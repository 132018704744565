import React from "react";
import PropTypes from "prop-types";
import { Icon } from "semantic-ui-react";

const minLength8 = /^[\s\S]{8,}$/,
  // minLength20 = /^[\s\S]{20,}$/,
  upper = /[A-Z]/,
  lower = /[a-z]/,
  number = /[0-9]/,
  // Do not count space as a special character
  special = /[^A-Za-z0-9\s]/;

const getCategories = (password) => {
  var categories = 0;
  if (lower.test(password)) categories += 1;
  if (upper.test(password)) categories += 10;
  if (special.test(password)) categories += 100;
  if (number.test(password)) categories += 1000;
  return categories;
};

const validateCategories = (password) => {
  // Using module 9 arithmetic for summation, see:
  // https://stackoverflow.com/questions/38334652/sum-all-the-digits-of-a-number-javascript
  const categories = getCategories(password);
  if (((categories - 1) % 9) + 1 >= 3) {
    return true;
  }
  return false;
};

const hasLength8 = (password) => {
  if (minLength8.test(password)) {
    return true;
  }
  return false;
};

const hasLength20 = (password) => {
  // DISABLED
  // if (minLength20.test(password)) {
  //   return true;
  // }
  return false;
};

const hasLowercase = (password) => {
  return Math.floor(getCategories(password) / 1) % 10;
};

const hasUppercase = (password) => {
  return Math.floor(getCategories(password) / 10) % 10;
};

const hasSpecial = (password) => {
  return Math.floor(getCategories(password) / 100) % 10;
};

const hasDigit = (password) => {
  return Math.floor(getCategories(password) / 1000) % 10;
};

export const validatePassword = (password) => {
  // DISABLED
  // If password has >= 20 chars, then no further validation needed.
  // if (hasLength20(password)) {
  //   return true;
  // }

  // If password contains at least 3 categories from (lower, upper, special, digit),
  // and has >= 8 length, then the password is valid.
  if (validateCategories(password) && hasLength8(password)) {
    return true;
  }

  return false;
};

const PasswordValidTick = ({ setNewPasswordError, newPassword }) => {
  return (
    <>
      {newPassword && !hasLength20(newPassword) && (
        <div>
          <Icon name="circle" className="icon-circle" />
          Minimum 8 characters:{" "}
          {hasLength8(newPassword) ? (
            <Icon color="green" name="checkmark" />
          ) : (
            <>
              <Icon color="red" name="cancel" />
              {setNewPasswordError(
                "Passwords should have more than 8 characters"
              )}
            </>
          )}
          <br />
          <Icon name="circle" className="icon-circle" />
          Has at least 3 categories:{" "}
          {validateCategories(newPassword) ? (
            <Icon color="green" name="checkmark" />
          ) : (
            <Icon color="red" name="cancel" />
          )}
        </div>
      )}
      {/* DISABLED
       {newPassword && hasLength20(newPassword) && (
        <div>
          <Icon name="circle" className="icon-circle" />
          Minimum 20 characters: <Icon color="green" name="checkmark" />
        </div>
      )} */}
      {newPassword &&
        !validateCategories(newPassword) &&
        !hasLength20(setNewPasswordError, newPassword) && (
          <div className="password-policy-margin-left">
            <Icon name="circle outline" className="icon-circle" />
            Lowercase character:{" "}
            {hasLowercase(newPassword) ? (
              <Icon color="green" name="checkmark" />
            ) : (
              <>
                {setNewPasswordError("Missing lower case letter")}
                <Icon color="red" name="cancel" />
              </>
            )}
            <br />
            <Icon name="circle outline" className="icon-circle" />
            Uppercase character:{" "}
            {hasUppercase(newPassword) ? (
              <Icon color="green" name="checkmark" />
            ) : (
              <>
                {setNewPasswordError("Missing upper case letter")}
                <Icon color="red" name="cancel" />
              </>
            )}
            <br />
            <Icon name="circle outline" className="icon-circle" />
            Special character:{" "}
            {hasSpecial(newPassword) ? (
              <Icon color="green" name="checkmark" />
            ) : (
              <>
                {setNewPasswordError("Missing special character")}
                <Icon color="red" name="cancel" />
              </>
            )}
            <br />
            <Icon name="circle outline" className="icon-circle" />
            Digit:{" "}
            {hasDigit(newPassword) ? (
              <Icon color="green" name="checkmark" />
            ) : (
              <>
                {setNewPasswordError("Missing digit")}
                <Icon color="red" name="cancel" />
              </>
            )}
          </div>
        )}
    </>
  );
};

PasswordValidTick.propTypes = {
  newPassword: PropTypes.string,
  setNewPasswordError: PropTypes.func.isRequired,
};

export default PasswordValidTick;
