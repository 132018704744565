import { types as at, successAction } from "@authzsvc/api-lib";

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case successAction(at.GET_ACCOUNT_PROVIDERS):
      return [...action.payload.data];
    default:
      return state;
  }
};
