import React from "react";
import capabilitiesService from "./capabilitiesService";

export const withApiCapabilities = (Component) => {
  return (props) => {
    return (
      <Component
        loadedCapabilities={capabilitiesService.loaded}
        capabilities={capabilitiesService.capabilities}
        {...props}
      />
    );
  };
};
