import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router";
import { Button, Form, Loader, Message } from "semantic-ui-react";
import { clearSSPRError, registerSSPRError } from "../../actions/hookActions";
import client from "./SelfServiceApiClient";
import SSPRWrapper from "./SSPRWrapper";

const SubmitSuccess = () => (
  <SSPRWrapper>
    <Message positive>
      <Message.Header>Password reset successful</Message.Header>
      <p>
        The operation has completed successfully, you may now log in using your
        account and new password.
      </p>
    </Message>
  </SSPRWrapper>
);

const CompleteSelfServicePasswordReset = ({ match }) => {
  const dispatch = useDispatch();
  const [submitted, setSubmitted] = useState(false);

  const { register, handleSubmit, errors, reset, setError, clearErrors } =
    useForm();

  const [loading, setLoading] = useState(false);

  const passwordCheck = (data) => {
    if (data.newPassword !== data.confirmNewPassword) {
      setError("passwordsMustMatch", "err");
      setTimeout(() => {
        clearErrors();
      }, 2000);
      return false;
    }
    return true;
  };

  const onSubmit = async (data) => {
    clearErrors();
    if (!passwordCheck(data)) {
      return;
    }
    setLoading(true);
    dispatch(clearSSPRError());

    const response = await client.finalizeReset(data);
    if (!response.ok) {
      dispatch(registerSSPRError(await response.json()));
    } else {
      setSubmitted(true);
    }
    reset();
    setLoading(false);
    console.log(data);
  };

  if (submitted) {
    return <SubmitSuccess />;
  }

  return (
    <SSPRWrapper>
      <Message>
        <p>Please insert your new password.</p>
      </Message>
      {loading && <Loader active inline="centered" />}

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Field
          error={(errors.newPassword || errors.passwordsMustMatch) && true}
        >
          <label htmlFor="newPassword">New password: </label>
          <input
            id="newPassword"
            name="newPassword"
            aria-label="Enter your new password"
            type="password"
            ref={register({ required: true })}
          />
          {errors.newPassword && (
            <span style={{ color: "red" }}>This field is required!</span>
          )}
        </Form.Field>
        <Form.Field
          error={
            (errors.confirmNewPassword || errors.passwordsMustMatch) && true
          }
        >
          <label htmlFor="confirmNewPassword">Confirm new password: </label>
          <input
            id="confirmNewPassword"
            name="confirmNewPassword"
            aria-label="Enter your new password again to confirm"
            type="password"
            ref={register({ required: true })}
          />
          {errors.confirmNewPassword && (
            <span style={{ color: "red" }}>This field is required!</span>
          )}
        </Form.Field>
        {errors.passwordsMustMatch && (
          <span style={{ color: "red" }}>Passwords must match!</span>
        )}
        <input
          name="code"
          type="hidden"
          value={match.params.code}
          ref={register}
        />
        <Form.Field>
          <Button type="submit" primary>
            Submit
          </Button>
        </Form.Field>
      </Form>
    </SSPRWrapper>
  );
};

CompleteSelfServicePasswordReset.propTypes = {};

export default withRouter(CompleteSelfServicePasswordReset);
