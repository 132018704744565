import React, { useEffect, useState } from "react";
import { ScreenSize } from "./constants";

export const useIsDesktop = () => {
  const [isDesktop, setDesktop] = useState(
    window.innerWidth > ScreenSize.Mobile
  );

  const updateMedia = () => {
    setDesktop(window.innerWidth > ScreenSize.Mobile);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  return isDesktop;
};

/**
 * Hook used to manually update the page title
 * ( basic title [category] - action )
 * @type {React.Context<{setAction: setAction, setCategory: setCategory, title: string}>}
 */
export const TitleContext = React.createContext({
  title: "",
  setCategory: () => {},
  setAction: () => {},
});
