import React from "react";
import PropTypes from "prop-types";
import { Placeholder } from "semantic-ui-react";

const PlaceholderLineLoader = ({ count = 1 }) => (
  <>
    {[...Array(count).keys()].map((key) => (
      <Placeholder key={key} fluid>
        <Placeholder.Paragraph>
          <Placeholder.Header>
            <Placeholder.Line />
          </Placeholder.Header>
          <Placeholder.Line />
        </Placeholder.Paragraph>
      </Placeholder>
    ))}
  </>
);

PlaceholderLineLoader.propTypes = {
  count: PropTypes.number,
};

export default PlaceholderLineLoader;
