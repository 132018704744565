import { FullPageLoader } from "@authzsvc/common-react-components";
import KeycloakWrapper from "@authzsvc/keycloak-js-react";
import Keycloak from "keycloak-js";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import "./assets/App.css";
import MainRouter from "./components/MainRouter";
import * as cfg from "./config";
import TitleProvider from "./components/title/TitleProvider";

const App = ({ ready }) => {
  return (
    <KeycloakWrapper
      keycloak={
        new Keycloak({
          url: cfg.keycloakUrl,
          realm: cfg.keycloakRealm,
          clientId: cfg.keycloakClientId,
        })
      }
      refresh={cfg.refreshKeycloakToken}
      keycloakParams={{
        onLoad: "login-required",
        flow: "standard",
        promiseType: "native",
      }}
    >
      {ready ? (
        <TitleProvider>
          <MainRouter />
        </TitleProvider>
      ) : (
        <FullPageLoader />
      )}
    </KeycloakWrapper>
  );
};

export default withRouter(
  connect((state) => ({
    ready: state.keycloak.ready,
  }))(App)
);
