import React from "react";
import { Tooltip } from "antd";
import { Icon } from "semantic-ui-react";

import styles from "./BlockedWarning.module.scss";

const BlockedWarning = () => {
  return (
    <Tooltip placement="top" title="Account blocked">
      <Icon className={styles.blockedWarning} />
    </Tooltip>
  );
};

export default BlockedWarning;
