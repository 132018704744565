import { createJsonPatchOp, errorAction, types as at } from "@authzsvc/api-lib";
import { Table, Modal, notification } from "antd";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Container, Icon, Loader, Message } from "semantic-ui-react";
import { getIdentityMemberships, patchIdentity } from "../../actions/api";
import { isApiCallLoading } from "../../selectors";
import _ from "lodash";

const DEF_CG_GID = 2766;

const GidAssignmentModal = ({
  // redux props
  loading,
  identity,
  // redux actions
  onSubmit,
  getIdentityMemberships,
  patchIdentity,
}) => {
  const [visible, setVisible] = useState(false);

  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    const load = async () => {
      const memberships = await getIdentityMemberships(identity.id, {
        filter: "isComputingGroup:true",
        recursive: true,
      });
      setDataSource(memberships.payload.data);
    };
    if (identity.id) {
      load();
    }
  }, [identity.id, identity.gid, setDataSource, getIdentityMemberships]);

  const modalColumns = [
    {
      title: "Group Id",
      dataIndex: "groupIdentifier",
    },
    {
      title: "GID",
      dataIndex: "gid",
    },
    {
      title: "Action",
      dataIndex: "id",
      render: (_, record) => (
        <Button positive onClick={() => handleSelect(record)}>
          <Icon name="check" />
        </Button>
      ),
    },
  ];

  const handleSelect = async (record) => {
    console.log("selected: ", record);
    const resp = await patchIdentity(identity.id, [
      createJsonPatchOp("gid", record.gid, "replace"),
    ]);
    if (resp.type === errorAction(at.PATCH_IDENTITY)) {
      notification["error"]({
        message: resp.payload.title,
        description: resp.payload.data.message,
      });
    }
    setVisible(false);
    onSubmit(record);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const displayGroupIdentifier = () => {
    if (selectedGroup) {
      return selectedGroup.groupIdentifier;
    }
    if (identity.gid === DEF_CG_GID) {
      return "def-cg";
    }
    return "Unknown group";
  };

  const selectedGroup = _.find(dataSource, (g) => g.gid === identity.gid);
  const nonDefCgDataSource = _.filter(
    dataSource,
    (g) => g.groupIdentifier !== "def-cg"
  );

  return (
    <>
      {identity && identity.id && (
        <>
          {loading && <Loader active inline />}
          {!loading && (
            <span>
              {displayGroupIdentifier()}{" "}
              {identity.gid && <span>(gid={identity.gid})</span>}{" "}
            </span>
          )}
          <Button
            onClick={() => setVisible(true)}
            floated="right"
            className="primary"
            labelPosition="left"
            icon
          >
            <Icon name="edit outline" />
            Edit Computing group
          </Button>
          <Modal
            centered
            title={"Select default computing group"}
            visible={visible}
            onOk={handleCancel}
            onCancel={handleCancel}
            confirmLoading={loading}
            width={1000}
          >
            <Container>
              <Message
                warning
                icon="warning"
                header="Preview feature"
                content={
                  <>
                    This is a preview feature, all changes performed here will
                    not be propagated to external systems, such as Active
                    Directory.
                  </>
                }
              />
              <p>
                Select one of the computing groups below to assign its GID to
                you.
              </p>
              <Table
                loading={loading}
                rowKey="id"
                pagination={{
                  pageSize: 50,
                }}
                columns={modalColumns}
                dataSource={nonDefCgDataSource}
              />
            </Container>
          </Modal>
        </>
      )}
    </>
  );
};

GidAssignmentModal.propTypes = {
  identity: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    loading:
      isApiCallLoading(state, at.GET_OWNED_IDENTITIES) ||
      isApiCallLoading(state, at.GET_IDENTITY_MEMBERSHIPS),
  }),
  {
    getIdentityMemberships,
    patchIdentity,
  }
)(GidAssignmentModal);
