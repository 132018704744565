import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import {
  Segment,
  Label,
  Container,
  Message,
  Icon,
  Checkbox,
  Form,
  Header,
  Button,
  Modal,
  List,
  Select,
} from "semantic-ui-react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  mfaRoleIdentifier,
  sidebar_identities,
  mfaCriticalRoleIdentifier,
  authzsvcApiID,
} from "../../config";

import {
  selectAccountById,
  isApiCallLoading,
  selectAccountProviders,
  userTokenHasRole,
  selectMfaSettings,
} from "../../selectors";
import { types as at, errorAction } from "@authzsvc/api-lib";
import {
  getAccountById,
  getMfaSettingsForAccount,
  setMfaSettingsForAccount,
  getAllUserRolesForApp,
} from "../../actions/api";
import { LoadingWrapper } from "@authzsvc/common-react-components";
import { setBreadcrumbs } from "../../actions/breadcrumbs";
import { parseItemToList } from "../../utils";
import BlockedResource from "../common/BlockedResource";
import { notification, Popconfirm } from "antd";
import { withApiCapabilities } from "../../wrappers";

const fields = {
  displayName: { title: "Name" },
  emailAddress: { title: "Email address" },
  resourceCategory: { title: "Resource category" },
  expirationDeadline: { title: "Expiration deadline", isDate: true },
};

const AccountDetails = ({
  // redux props
  account,
  accountProviders,
  breadcrumbs,
  loading,
  loadingCredentials,
  match,
  mfa,
  keycloak,
  accountManagementOn,
  mfaCredentials,
  // redux actions
  getAccountById,
  getMfaSettingsForAccount,
  setMfaSettingsForAccount,
  setBreadcrumbs,
  getAllUserRolesForApp,
}) => {
  const [allCredentials, setAllCredentials] = useState(undefined);
  const [preferredCredentials, setPreferredCredentials] = useState(undefined);
  const [webAuthnEnableModalOpen, setWebAuthnEnableModalOpen] = useState(false);
  const [webAuthnModalDisableOpen, setWebAuthnModalDisableOpen] =
    useState(false);
  const [otpModalDisableOpen, setOtpModalDisableOpen] = useState(false);
  const [criticalUser, setCriticalUser] = useState(false);

  const criticalUserMessage = `You are a critical user so must always have at least one 2FA method enabled. Your 2FA settings will be applied to the main SSO login form`;
  const nonCriticalUserMessage = `Your 2FA settings will be applied to the "Two-factor Authentication" optional login form.`;
  const resetOneTimePwdMessage =
    "If you're resetting OTP due to a phone change, check if your authenticator app supports exporting credentials to your new device. This can save you from unnecessary resets.";

  const disable2FAMessage = (factor) => {
    return `Are you sure you want to disable ${factor}? ${
      criticalUser ? criticalUserMessage : ``
    }`;
  };

  const toggleOtpModal = () => {
    setOtpModalDisableOpen(!otpModalDisableOpen);
  };

  const toggleMfaEnableModal = () => {
    setWebAuthnEnableModalOpen(!webAuthnEnableModalOpen);
  };

  const toggleWebAuthnDisableModal = () => {
    setWebAuthnModalDisableOpen(!webAuthnModalDisableOpen);
  };

  const confirmResetWebAuthn = async () => {
    setWebAuthnEnableModalOpen(false);
    enableWebAuthn();
  };

  const enableWebAuthn = async () => {
    const newSettings = {
      ...mfaCredentials,
      webauthn: { initialization_required: true, enabled: true },
    };
    const resp = await setMfaSettingsForAccount(account.id, newSettings);
    if (resp.type === errorAction(at.SET_MFA_SETTINGS_FOR_ACCOUNT)) {
      notification["error"]({
        message: resp.payload.title,
        description: resp.payload.data,
      });
    }
    await getMfaSettingsForAccount(account.id);
  };

  const resetOTP = async () => {
    const newSettings = {
      ...mfaCredentials,
      otp: { initialization_required: true, enabled: true },
    };
    const resp = await setMfaSettingsForAccount(account.id, newSettings);
    if (resp.type === errorAction(at.SET_MFA_SETTINGS_FOR_ACCOUNT)) {
      notification["error"]({
        message: resp.payload.title,
        description: resp.payload.data,
      });
    }
    await getMfaSettingsForAccount(account.id);
  };

  const toggleWebAuthn = async () => {
    if (mfaCredentials.webauthn.enabled === true) {
      setWebAuthnModalDisableOpen(true);
    } else {
      toggleMfaEnableModal();
    }
  };

  const disableWebAuthn = async () => {
    let newSettings = { ...mfaCredentials };
    newSettings.webauthn.enabled = false;
    newSettings.webauthn.initialization_required = false;
    const resp = await setMfaSettingsForAccount(account.id, newSettings);
    if (resp.type === errorAction(at.SET_MFA_SETTINGS_FOR_ACCOUNT)) {
      notification["error"]({
        message: resp.payload.title,
        description: resp.payload.data,
      });
    }
    await getMfaSettingsForAccount(account.id);
  };

  const confirmWebAuthnDisable = () => {
    setWebAuthnModalDisableOpen(false);
    disableWebAuthn();
  };

  const toggleOTP = async (o, e, forceReset = false) => {
    if (mfaCredentials.otp.enabled === true && !forceReset) {
      setOtpModalDisableOpen(true);
    } else {
      let newSettings = { ...mfaCredentials };
      newSettings.otp.enabled = !mfaCredentials.otp.enabled;
      newSettings.otp.initialization_required = newSettings.otp.enabled;
      const resp = await setMfaSettingsForAccount(account.id, newSettings);
      if (resp.type === errorAction(at.SET_MFA_SETTINGS_FOR_ACCOUNT)) {
        notification["error"]({
          message: resp.payload.title,
          description: resp.payload.data,
        });
      }
      await getMfaSettingsForAccount(account.id);
    }
  };

  const confirmOtpDisable = () => {
    setOtpModalDisableOpen(false);
    toggleOTP(null, null, true);
  };

  const togglePreferredCredential = async () => {
    let newSettings = { ...mfaCredentials };
    // This flips the preferred credential between OTP and WebAuthn.
    newSettings.otp.preferred = !newSettings.otp.preferred;
    newSettings.webauthn.preferred = !newSettings.webauthn.preferred;

    const resp = await setMfaSettingsForAccount(account.id, newSettings);
    if (resp.type === errorAction(at.SET_MFA_SETTINGS_FOR_ACCOUNT)) {
      notification["error"]({
        message: resp.payload.title,
        description: resp.payload.data,
      });
    }
    await getMfaSettingsForAccount(account.id);
  };

  const isCernAccount = useCallback(() => {
    if (!account || !accountProviders) {
      return false;
    }
    const prov = accountProviders.find(
      (a) => a.id === account.accountProviderId
    );
    if (!prov) {
      return false;
    }
    return prov.loaValue === 5;
  }, [account, accountProviders]);

  useEffect(() => {
    if (!account) {
      getAccountById(match.params.id);
    }
    setBreadcrumbs([
      ["/", "Account overview"],
      ["/accounts/details", account && account.uniqueIdentifier],
    ]);
  }, [account, match, breadcrumbs, getAccountById, setBreadcrumbs]);

  useEffect(() => {
    if (account && isCernAccount()) {
      getMfaSettingsForAccount(account.id);
    }
  }, [account, getMfaSettingsForAccount, isCernAccount]);

  useEffect(() => {
    const getAsync = async () => {
      const roles = await getAllUserRolesForApp(authzsvcApiID);
      if (
        roles &&
        roles.payload &&
        roles.payload.data &&
        !roles.payload.data.innerExceptions
      ) {
        if (
          roles.payload.data.find(
            ({ name }) => name === mfaCriticalRoleIdentifier
          )
        ) {
          setCriticalUser(true);
        }
      }
    };
    getAsync();
  }, [getAllUserRolesForApp]);

  useEffect(() => {
    const setPreferred = async () => {
      if (
        mfaCredentials.webauthn.credential_id &&
        mfaCredentials.otp.credential_id
      ) {
        setAllCredentials([
          {
            id: mfaCredentials.webauthn.credential_id,
            value: "webauthn",
            text: mfaCredentials.webauthn.text,
          },
          {
            id: mfaCredentials.otp.credential_id,
            value: "otp",
            text: mfaCredentials.otp.text,
          },
        ]);
        if (mfaCredentials.webauthn.preferred === true) {
          setPreferredCredentials("webauthn");
        } else {
          setPreferredCredentials("otp");
        }
      }
    };

    setPreferred();
  }, [mfaCredentials]);

  const secondFactorPlaceholder = (
    <Segment placeholder>
      <Header icon>
        <Icon name="lock" />
        Second Factor Required
        <Header.Subheader>
          For security reasons you can only modify your Second Factor settings
          if you are authenticated with a Second Factor token. All CERN Users
          with a smart phone are able to authenticate using a Second Factor, you
          just need to download and register a One-time-password (OTP)
          application, see the
          <a href="http://auth.docs.cern.ch/user-documentation/two-factor-authentication/#setting-up-otp-for-smartphones">
            {" "}
            Documentation{" "}
          </a>
          for suggestions. Log out now and authenticate again, choosing the{" "}
          <b>Log in with Two-factor</b> option.
        </Header.Subheader>
      </Header>
    </Segment>
  );

  const secondFactorAuthSegment = (
    <LoadingWrapper loading={loadingCredentials}>
      {!mfa && mfaCredentials.otp.enabled | mfaCredentials.webauthn.enabled ? (
        <>{secondFactorPlaceholder}</>
      ) : (
        <>
          <Header as="h4">
            2FA (Two Factor Authentication)
            <Header.Subheader>
              {" "}
              {criticalUser ? criticalUserMessage : nonCriticalUserMessage}
            </Header.Subheader>
          </Header>
          <Form>
            <Form.Field>
              <Checkbox
                checked={mfaCredentials.otp.enabled}
                onChange={toggleOTP}
                disabled={
                  criticalUser &&
                  mfaCredentials.otp.enabled &&
                  !mfaCredentials.webauthn.enabled
                }
                toggle
                label={
                  <label>
                    Enable One Time Password credentials (OTP) for a{" "}
                    <a
                      href="http://auth.docs.cern.ch/user-documentation/two-factor-authentication/#setting-up-otp-for-smartphones"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      compatible application
                    </a>{" "}
                    {mfaCredentials.otp.initialization_required && (
                      <b>(requires initialization on your next login)</b>
                    )}
                  </label>
                }
                id="otp-enabled"
              />
            </Form.Field>
            <Form.Field>
              <Checkbox
                checked={mfaCredentials.webauthn.enabled}
                onChange={toggleWebAuthn}
                toggle
                disabled={
                  criticalUser &&
                  !mfaCredentials.otp.enabled &&
                  mfaCredentials.webauthn.enabled
                }
                label={
                  <label>
                    Enable WebAuthn credentials for Yubikey or any compatible
                    device{" "}
                    {mfaCredentials.webauthn.initialization_required && (
                      <b>(requires initialization on your next login)</b>
                    )}
                  </label>
                }
                id="webauthn-enabled"
              />
            </Form.Field>
          </Form>
          {allCredentials &&
            mfaCredentials.webauthn.credential_id &&
            mfaCredentials.otp.credential_id &&
            preferredCredentials && (
              <>
                <Header as="h5">Default login method</Header>
                <Select
                  options={allCredentials}
                  defaultValue={preferredCredentials}
                  onChange={togglePreferredCredential}
                />
              </>
            )}
          <Header as="h5">
            Reset credential
            <Header.Subheader>{resetOneTimePwdMessage}</Header.Subheader>
          </Header>
          <Popconfirm
            title={`This will reset your OTP (one time password) credentials. Are you sure?`}
            onConfirm={() => resetOTP()}
          >
            <Button id="resetOTPButton" primary>
              Reset OTP
            </Button>
          </Popconfirm>
          <Popconfirm
            title="This will reset your WebAuthn credentials. Are you sure?"
            onConfirm={() => toggleMfaEnableModal()}
          >
            <Button id="resetWebAuthnButton" primary>
              Reset WebAuthn
            </Button>
          </Popconfirm>
        </>
      )}
    </LoadingWrapper>
  );

  const webAuthnEnableModal = (
    <Modal open={webAuthnEnableModalOpen} size="small">
      <Header icon="lock" content="WebAuthn Configuration" />
      <Modal.Content>
        <h1>Ready to set up WebAuthn?</h1>
        <List ordered>
          <List.Item>
            <b>DO NOT proceed without a WebAuthn compatible device</b>, this
            could be a Yubikey or an inbuilt token generator on your device
            (such as a fingerprint reader)
          </List.Item>
          <List.Item>
            Open a new private/incognito window, or use a different web browser.
            Keep this page open throughout the process - so that you could
            disable WebAuthn here, in case you do not manage to complete the
            configuration.
          </List.Item>
          <List.Item>
            Authenticate to{" "}
            <a
              href={sidebar_identities}
              target="_blank"
              rel="noopener noreferrer"
            >
              SSO
            </a>{" "}
            again
          </List.Item>
          <List.Item>
            Once authenticated you will be prompted to register your WebAuthn
            token.{" "}
            <i>
              Hint: For Yubikeys this means inserting the Yubikey into your USB
              drive and tapping the flashing light
            </i>{" "}
          </List.Item>
        </List>
        You can find more help in the{" "}
        <a
          href="https://cern.service-now.com/service-portal?id=kb_article&n=KB0006587"
          target="_blank"
          rel="noopener noreferrer"
        >
          CERN Service Portal
        </a>
        .
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={toggleMfaEnableModal} color="grey">
          Cancel
        </Button>
        <Button onClick={confirmResetWebAuthn} color="green">
          <Icon name="checkmark" /> Got it
        </Button>
      </Modal.Actions>
    </Modal>
  );

  const webAuthnDisableModal = (
    <Modal
      open={webAuthnModalDisableOpen}
      header="Disable WebAuthn"
      content={disable2FAMessage("WebAuthn")}
      actions={[
        {
          key: "cancel",
          content: "Cancel",
          positive: false,
          onClick: toggleWebAuthnDisableModal,
        },
        {
          key: "done",
          content: "Disable",
          positive: true,
          onClick: confirmWebAuthnDisable,
        },
      ]}
    />
  );

  const otpDisableModal = (
    <Modal
      open={otpModalDisableOpen}
      header="Disable OTP"
      content={disable2FAMessage("OTP")}
      actions={[
        {
          key: "cancel",
          content: "Cancel",
          positive: false,
          onClick: toggleOtpModal,
        },
        {
          key: "done",
          content: "Disable",
          positive: true,
          onClick: confirmOtpDisable,
        },
      ]}
    />
  );

  return (
    <>
      {webAuthnEnableModal}
      {webAuthnDisableModal}
      {otpDisableModal}
      <Container>
        <LoadingWrapper loading={loading}>
          <>
            <BlockedResource resource={account} />
            {account !== undefined ? (
              <>
                <Segment>
                  <Label color="red" ribbon>
                    Identifier: {account.uniqueIdentifier}
                  </Label>
                  {parseItemToList(fields, account)}
                </Segment>
                <Segment>
                  {isCernAccount() && secondFactorAuthSegment}
                  {!isCernAccount() && (
                    <Header as="h4">
                      2FA (Two Factor Authentication)
                      <Header.Subheader>
                        2FA is only available for CERN accounts.
                      </Header.Subheader>
                    </Header>
                  )}
                </Segment>
              </>
            ) : (
              <Message icon>
                <Icon name="exclamation circle" />
                <Message.Content>No Account found for this ID</Message.Content>
              </Message>
            )}
          </>
        </LoadingWrapper>
      </Container>
    </>
  );
};

AccountDetails.propTypes = {
  account: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  loadingCredentials: PropTypes.bool.isRequired,
  getAccountById: PropTypes.func.isRequired,
  // redux fcs
  setBreadcrumbs: PropTypes.func.isRequired,
};

// TODO add connected react router for getting the match params inside here
export default withApiCapabilities(
  withRouter(
    connect(
      (state, ownProps) => ({
        account: selectAccountById(state, ownProps.match.params.id),
        loading:
          isApiCallLoading(state, at.GET_ACCOUNT_BY_ID) ||
          isApiCallLoading(state, at.GET_ACCOUNT_PROVIDERS) ||
          !ownProps.loadedCapabilities,
        loadingCredentials:
          isApiCallLoading(state, at.GET_MFA_SETTINGS_FOR_ACCOUNT) ||
          isApiCallLoading(state, at.SET_MFA_SETTINGS_FOR_ACCOUNT) ||
          isApiCallLoading(state, at.GET_ACCOUNTS),
        accountProviders: selectAccountProviders(state),
        mfa: userTokenHasRole(state, mfaRoleIdentifier),
        mfaCredentials: selectMfaSettings(state),
        keycloak: state.keycloak.instance,
      }),
      {
        getAccountById,
        getMfaSettingsForAccount,
        setMfaSettingsForAccount,
        setBreadcrumbs,
        getAllUserRolesForApp,
      }
    )(AccountDetails)
  )
);
