import React from "react";
import PropTypes from "prop-types";
import { Icon } from "semantic-ui-react";

const PasswordMatchTick = ({ newPassword, confirmNewPassword }) => {
  return (
    <>
      {newPassword && confirmNewPassword && (
        <div>
          <Icon name="circle" className="icon-circle" />
          Passwords match:{" "}
          {confirmNewPassword === newPassword ? (
            <Icon color="green" name="checkmark" />
          ) : (
            <Icon color="red" name="cancel" />
          )}
        </div>
      )}
    </>
  );
};

PasswordMatchTick.propTypes = {
  newPassword: PropTypes.string,
  confirmNewPassword: PropTypes.string,
};

export default PasswordMatchTick;
