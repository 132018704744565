export const IdentityTypes = Object.freeze({
  Person: "Person",
  Secondary: "Secondary",
  Service: "Service",
  Undefined: "Undefined",
});

export const ScreenSize = Object.freeze({
  Mobile: 708,
  Desktop: 1450,
});
