import { apiActions } from "@authzsvc/api-lib";
import { combineReducers } from "redux";

// The state gets automatically generated based on the apiActions

const createAllLoadingReducers = () => {
  let reducers = {};
  for (var actionType in apiActions) {
    if (apiActions.hasOwnProperty(actionType)) {
      reducers = {
        ...reducers,
        [actionType]: createLoadingReducer(false, actionType),
      };
    }
  }
  return reducers;
};

const createLoadingReducer = (initialState, apiActionType) => {
  const [requestType, successType, errorType] = apiActions[apiActionType];
  return (state = initialState, action) => {
    switch (action.type) {
      case requestType:
        return true;
      case successType:
        return false;
      case errorType:
        return false;
      default:
        return state;
    }
  };
};

export default combineReducers(createAllLoadingReducers());
