import { types as at, successAction } from "@authzsvc/api-lib";

const initialState = {
  toApprove: [],
  my: [],
  activeOwner: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case successAction(at.GET_REQUESTS):
      return {
        ...state,
        my: action.payload.data,
      };
    case successAction(at.GET_REQUESTS_TO_APPROVE):
      return {
        ...state,
        toApprove: action.payload.data,
      };
    case successAction(at.RESOLVE_IDENTITY):
      return {
        ...state,
        activeOwner: action.payload.data,
      };
    default:
      return state;
  }
};
