import { types as at, successAction } from "@authzsvc/api-lib";

const initialState = {
  accounts: [],
  primaryAccount: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case successAction(at.GET_CURRENT_IDENTITY_ACCOUNTS):
      return { ...state, accounts: [...action.payload.data] };
    case successAction(at.GET_ACCOUNT_BY_ID):
      const index = state.accounts.indexOf(action.payload.data);
      if (index < 0) {
        return { ...state, accounts: [...state.accounts, action.payload.data] };
      } else {
        return state;
      }
    case successAction(at.GET_CURRENT_PRIMARY_ACCOUNT):
      return { ...state, primaryAccount: action.payload.data };
    default:
      return state;
  }
};
