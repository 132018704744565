import { selfServiceApiUrl } from "../../config";

class SelfServiceApiClient {
  baseUrl = selfServiceApiUrl;

  _formatUrl(relativeUrl) {
    return `${this.baseUrl}/${relativeUrl}`;
  }

  async _request(relativeUrl, options = {}) {
    if (options.method !== "GET") {
      options.headers = { "Content-Type": "application/json" };
    }
    return await fetch(this._formatUrl(relativeUrl), options);
  }

  getCaptcha() {
    return this._request("captcha");
  }

  getCaptchaAudioUrl(captchaId) {
    return this._formatUrl(`captcha/audio/${captchaId}`);
  }

  triggerReset(payload) {
    return this._request("reset", {
      method: "POST",
      body: JSON.stringify(payload),
    });
  }

  finalizeReset(payload) {
    return this._request("reset/confirm", {
      method: "POST",
      body: JSON.stringify(payload),
    });
  }

  activateAccount(payload) {
    return this._request("activate", {
      method: "POST",
      body: JSON.stringify(payload),
    });
  }
}

export default new SelfServiceApiClient();
