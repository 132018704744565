import { DatePicker } from "antd";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router";
import { Button, Form, Icon, Loader, Message, List } from "semantic-ui-react";
import { clearSSPRError, registerSSPRError } from "../../actions/hookActions";
import client from "./SelfServiceApiClient";
import SSPRWrapper from "./SSPRWrapper";
import "../../assets/App.css";

const SubmitSuccess = () => (
  <SSPRWrapper>
    <Message positive>
      <Message.Header>Email successfully sent</Message.Header>
      <p>
        Please check your external email address and click on the link provided
        to complete the password reset process.
      </p>
    </Message>
  </SSPRWrapper>
);

const SelfServicePasswordReset = () => {
  const [captchaResponse, setCaptchaResponse] = useState(undefined);
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showAudio, setShowAudio] = useState(false);

  const dispatch = useDispatch();

  const { register, control, handleSubmit, errors, reset } = useForm();

  const onSubmit = async (data) => {
    setLoading(true);
    dispatch(clearSSPRError());
    const date = new Date(
      Date.UTC(
        data.dateOfBirth.year(),
        data.dateOfBirth.month(),
        data.dateOfBirth.date()
      )
    );
    const isoDate = date.toISOString();
    const response = await client.triggerReset({
      accountName: data.login,
      dateOfBirth: isoDate,
      captcha: {
        id: captchaResponse.id,
        answer: data.captchaAnswer,
      },
    });

    if (response.ok) {
      setSubmitted(true);
    } else {
      dispatch(registerSSPRError(await response.json()));
    }
    reset({ ...data, captchaAnswer: "" });
    setLoading(false);
    await reloadCaptcha();
  };

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const data = await client.getCaptcha();
      setCaptchaResponse(await data.json());
      setLoading(false);
    };
    if (!captchaResponse) {
      getData();
    }
  }, [captchaResponse, setCaptchaResponse]);

  const reloadCaptcha = async (e) => {
    setLoading(true);
    setShowAudio(false);
    const data = await client.getCaptcha();
    setCaptchaResponse(await data.json());
    setLoading(false);
  };

  if (submitted) {
    return <SubmitSuccess />;
  }

  return (
    <SSPRWrapper>
      <Message info>
        <b>
          Please note that this page is for active CERN members to reset their
          primary CERN account's password.
        </b>
        <List bulleted>
          <List.Item>
            To reset the password for a secondary or service account that you
            own, please visit the{" "}
            <a href="https://users-portal.web.cern.ch">Accounts Portal</a> and
            authenticate with your primary account.
          </List.Item>
          <List.Item>
            To reset the password for an external (guest or lightweight)
            account, please follow the guidelines provided in the{" "}
            <a href="https://cern.service-now.com/service-portal?id=kb_article&n=KB0007233">
              CERN Service Portal
            </a>
            .
          </List.Item>
        </List>
      </Message>
      <p>
        After completing the following form you will receive a confirmation
        email at your registered <b> external email address </b> to reset your
        password. If you do not have an external email address or are locked out
        of the form,{" "}
        <b>
          please contact the{" "}
          <a href="https://cern.service-now.com/service-portal?id=sc_cat_item&name=incident&fe=identity-account-management">
            Service Desk
          </a>
          .
        </b>
      </p>
      {loading && <Loader active inline="centered" />}
      {captchaResponse && (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Field>
            <label htmlFor="login">
              <h3>Username:</h3>{" "}
            </label>
            <input
              id="login"
              aria-label="Enter your username"
              name="login"
              type="text"
              ref={register({ required: true })}
            />
            {errors.login && (
              <span style={{ color: "red" }}>This field is required</span>
            )}
          </Form.Field>
          <Form.Field>
            <label>
              <h3>Date of birth (dd-mm-yyyy):</h3>
              <br />
              <Controller
                as={DatePicker}
                aria-label="Enter your Date of birth with format day,dash, month,dash year"
                format={"DD-MM-YYYY"}
                name="dateOfBirth"
                control={control}
                rules={{ required: true }}
                defaultValue={null}
              />
            </label>
            {errors.dateOfBirth && (
              <p style={{ color: "red" }}>This field is required</p>
            )}
          </Form.Field>
          <br />
          <Form.Field>
            <h3>Captcha:</h3>
            <h4>
              The code is valid for 60 seconds. Your answer will be considered
              incorrect if you don't answer within 60 seconds. You can reset the
              timer by generating a new code with the <b>Reset button</b> below.
            </h4>
            <img
              alt="captcha"
              name="captchaResponseImg"
              style={{ marginBottom: "4px" }}
              src={captchaResponse.img}
            />
            <Button
              type="button"
              icon
              onClick={() => reloadCaptcha()}
              labelPosition="right"
            >
              {" "}
              Reset
              <Icon name="redo" />
            </Button>
            <Button type="button" icon onClick={() => setShowAudio(!showAudio)}>
              {showAudio ? "Hide " : "Show "} audio
            </Button>
            {showAudio && (
              <div>
                <audio controls="controls" className="audio-element">
                  <source src={client.getCaptchaAudioUrl(captchaResponse.id)} />
                </audio>
              </div>
            )}
          </Form.Field>

          <Form.Field>
            <label htmlFor="code">
              <h4>Code:</h4>
            </label>
            <input
              id="code"
              aria-label="Enter your captcha code"
              name="captchaAnswer"
              type="text"
              ref={register({ required: true })}
            />
            {errors.captchaAnswer && (
              <span style={{ color: "red" }}>This field is required</span>
            )}
          </Form.Field>
          <Form.Field>
            <Button type="submit" disabled={loading} primary>
              Submit
            </Button>
          </Form.Field>
        </Form>
      )}
    </SSPRWrapper>
  );
};

export default withRouter(SelfServicePasswordReset);
