import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Icon } from "semantic-ui-react";
import { notification, Tooltip } from "antd";
import { deleteIdentity } from "../../actions/api";
import { types as at, successAction, errorAction } from "@authzsvc/api-lib";
import { connect } from "react-redux";

const DeleteIdentityModal = ({ identity, onSubmit, deleteIdentity }) => {
  const [open, setOpen] = useState(false);

  const close = () => {
    setOpen(false);
  };

  const submit = async () => {
    var result = await deleteIdentity(identity.id);
    if (result.type === successAction(at.DELETE_IDENTITY)) {
      close();
      onSubmit(identity.id);
    } else if (result.type === errorAction(at.DELETE_IDENTITY)) {
      notification["error"]({
        message: result.payload.title,
        description: result.payload.data.message,
      });
    }
  };

  return (
    <Modal
      size="tiny"
      closeOnEscape={false}
      open={open}
      closeOnDimmerClick={true}
      onClose={close}
      trigger={
        <Tooltip title="Delete login">
          <Button icon onClick={() => setOpen(true)} color="red">
            <Icon name="trash" />
          </Button>
        </Tooltip>
      }
    >
      <Modal.Header>
        <Icon name="trash" /> Delete login: {identity.upn}
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          Are you sure you want to delete{" "}
          <b>
            {identity.displayName} ({identity.upn})
          </b>
          ?
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={close}
          icon="cancel"
          labelPosition="left"
          color="red"
          content="Cancel"
        />
        <Button
          onClick={submit}
          positive
          labelPosition="left"
          icon="trash"
          content="Confirm"
        />
      </Modal.Actions>
    </Modal>
  );
};

DeleteIdentityModal.propTypes = {
  identity: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default connect(() => ({}), { deleteIdentity })(DeleteIdentityModal);
