import { types as at, successAction } from "@authzsvc/api-lib";

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case successAction(at.GET_MY_GROUPS):
      return [...action.payload.data];
    case successAction(at.GET_GROUP_BY_ID):
      const index = state.indexOf(action.payload);
      if (index < 0) {
        return [...state, action.payload.data];
      } else {
        return state;
      }
    case successAction(at.SEARCH_GROUP):
      return [...action.payload.data];
    default:
      return state;
  }
};
