import React, { useState } from "react";
import { Menu } from "semantic-ui-react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { withRouter } from "react-router";
import "../../assets/Toolbar.css";
import { getRequestsToApprove, logout } from "../../actions/api";
import { isCernPerson } from "../../selectors";
import { CernToolbar } from "@authzsvc/common-react-components";
import HeaderDropdownItems from "./HeaderDropdownItems";
import RequestsHeaderButton from "./RequestHeaderButton";

const HeaderMenuItems = ({
  authenticated,
  cernAccount,
  getRequestsToApprove,
  keycloak,
  userToken,
}) => {
  const [firstRequests, setFirstRequests] = useState(false);
  const getPendingRequests = () => {
    getRequestsToApprove();
  };

  const createDropdownMenuItems = () => {
    return <HeaderDropdownItems />;
  };

  if (authenticated && !firstRequests) {
    getPendingRequests();
    setFirstRequests(true);
  }

  return (
    <CernToolbar
      extraLogoutFct={logout}
      authenticated={authenticated}
      keycloak={keycloak}
      userToken={userToken}
      dropDownItems={createDropdownMenuItems()}
    >
      <Menu.Menu attached="top" style={{ overflow: "visible !important" }}>
        <Menu.Item
          as={NavLink}
          exact
          activeClassName="active"
          to="/"
          content="Home"
        />
        <Menu.Item
          as={NavLink}
          exact
          activeClassName="active"
          to="/groups"
          content="Groups"
        />
        <RequestsHeaderButton />
      </Menu.Menu>
    </CernToolbar>
  );
};

const requestInFinalState = (request) => {
  return request.status !== "Pending";
};

HeaderMenuItems.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  keycloak: PropTypes.object.isRequired,
};

export default withRouter(
  connect(
    (state) => ({
      authenticated: state.keycloak.authenticated,
      keycloak: state.keycloak.instance,
      userToken: state.keycloak.userToken,
      cernAccount: isCernPerson(state),
      pendingRequests: state.requests.toApprove.filter(
        (r) => requestInFinalState(r) === false
      ).length,
    }),
    { getRequestsToApprove, logout }
  )(HeaderMenuItems)
);
