import { errorAction, types as at } from "@authzsvc/api-lib";
import { notification, Tooltip } from "antd";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Icon, Table, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { getAccountsForIdentity } from "../../actions/api";
import { isApiCallLoading, selectAccountManagementOn } from "../../selectors";
import DeleteIdentityModal from "./DeleteIdentityModal";
import { withApiCapabilities } from "../../wrappers";

const AccountCell = ({
  identity,
  secondary,
  pageRefresh,
  loading,
  accountManagementOn,
  // redux actions
  getAccountsForIdentity,
}) => {
  const [account, setAccount] = useState(undefined);

  useEffect(() => {
    const getData = async () => {
      const resp = await getAccountsForIdentity(identity.id);
      if (resp && resp.payload && resp.payload.data) {
        setAccount(resp.payload.data[0]);
      } else if (resp.type === errorAction(at.GET_ACCOUNTS_FOR_IDENTITY)) {
        setAccount(null);
        notification["error"]({
          message: resp.payload.title,
          description: resp.payload.data.message,
        });
      }
    };
    if (account === undefined) {
      getData();
    }
  }, [identity, account, getAccountsForIdentity]);

  return (
    <>
      {!loading && (
        <Table.Row textAlign="center" key={identity.applicationId}>
          <Table.Cell>
            {!identity.blocked && (
              <Tooltip title="This account is enabled">
                <Icon color="green" name="checkmark" />
              </Tooltip>
            )}
            {identity.blocked && (
              <Tooltip title="This account is blocked">
                <Icon color="red" name="cancel" />
              </Tooltip>
            )}
            <span>
              {identity.displayName} ( {identity.upn} )
            </span>
          </Table.Cell>

          <Table.Cell textAlign="center">
            <>
              {secondary && (
                <>
                  <Link to={`/identities/${identity.id}`}>
                    <Button positive icon="edit" />
                  </Link>
                  {accountManagementOn && pageRefresh && (
                    <DeleteIdentityModal
                      onSubmit={pageRefresh}
                      identity={identity}
                    />
                  )}
                </>
              )}
            </>
          </Table.Cell>
        </Table.Row>
      )}
    </>
  );
};

AccountCell.propTypes = {
  identity: PropTypes.object.isRequired,
  secondary: PropTypes.bool.isRequired,
  pageRefresh: PropTypes.func,
  getAccountsForIdentity: PropTypes.func.isRequired,
};

export default withApiCapabilities(
  connect(
    (state, ownProps) => ({
      loading: isApiCallLoading(state, at.GET_ACCOUNTS_FOR_IDENTITY),
      accountManagementOn: selectAccountManagementOn(ownProps.capabilities),
    }),
    {
      getAccountsForIdentity,
    }
  )(AccountCell)
);
