import React from "react";
import { List, Message, Button, Icon, Modal, Table } from "semantic-ui-react";
import { NavLink } from "react-router-dom";

const renderPrimaryAccountMessage = () => (
  <List.Item>
    <List.Content>
      <Message size="mini">
        <Message.Content>
          <Message.Header>
            This is your main account, it will be used for email communication.
          </Message.Header>
        </Message.Content>
      </Message>
    </List.Content>
  </List.Item>
);

const AccountListItem = ({
  account,
  primary,
  onSelect,
  disabledPrimary,
  onUnlink,
}) => {
  const identifierName = account.accountProviderName
    ? `${account.accountProviderName} account`
    : "Unique identifier";

  return (
    <>
      <List.Item>
        <List.Content>
          <List.Header>
            <NavLink exact to={`/accounts/${account.id}`} className="nav-link">
              {identifierName}: {account.uniqueIdentifier}
              <br />
            </NavLink>
          </List.Header>
          <List.Description>
            <List.List>
              {primary && renderPrimaryAccountMessage()}
              <br />
              <List.Item>
                <Table basic="very">
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell width={1}>Name</Table.HeaderCell>
                      <Table.HeaderCell width={1}>Email</Table.HeaderCell>
                      <Table.HeaderCell width={1}>
                        Level of Assurance value
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>{account.displayName}</Table.Cell>
                      <Table.Cell>{account.emailAddress}</Table.Cell>
                      <Table.Cell>
                        {account.accountProviderLoa
                          ? account.accountProviderLoa
                          : "--"}
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </List.Item>
              <List.Item>
                {!primary && (
                  <Button
                    disabled={disabledPrimary}
                    primary
                    size="tiny"
                    onClick={() => onSelect(account.id)}
                  >
                    Make main account
                  </Button>
                )}
                {!primary && (
                  <Modal
                    trigger={
                      <Button size="tiny" color="red" icon>
                        Unlink account <Icon name="unlink" />
                      </Button>
                    }
                    header="Unlink this account"
                    content={
                      "Are you sure you want to unlink your " +
                      account.accountProviderName +
                      " account, " +
                      account.displayName +
                      " (" +
                      account.uniqueIdentifier +
                      ")? You will still be able to log " +
                      "in with this account but it will lose any authorisations associated to you."
                    }
                    actions={[
                      { key: "cancel", content: "Cancel", positive: false },
                      {
                        key: "done",
                        content: "Unlink",
                        positive: true,
                        onClick: () => {
                          onUnlink(account.id);
                        },
                      },
                    ]}
                  />
                )}
              </List.Item>
            </List.List>
          </List.Description>
        </List.Content>
      </List.Item>
    </>
  );
};

export default AccountListItem;
