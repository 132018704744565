import React, { useEffect, useState, useCallback } from "react";

import { connect } from "react-redux";
import { withRouter } from "react-router";
import PropTypes from "prop-types";

import { errorAction, types as at } from "@authzsvc/api-lib";
import { selectFocusedRequest, isApiCallLoading } from "../../selectors";
import { setBreadcrumbs } from "../../actions/breadcrumbs";
import {
  cancelRequest,
  denyRequest,
  approveRequest,
  resolveIdentity,
} from "../../actions/api";
import { RequestDetails } from "@authzsvc/common-react-components";
import { notification } from "antd";
import * as cfg from "../../config";

const RequestDetailsWrapper = (props) => {
  const {
    //data
    breadcrumbs,
    requestData,
    history,
    //actions
    setBreadcrumbs,
    cancelRequest,
    approveRequest,
    denyRequest,
    resolveIdentity,
  } = props;

  useEffect(() => {
    setBreadcrumbs(breadcrumbs);
  }, [breadcrumbs, setBreadcrumbs]);

  const [request, setRequest] = useState(requestData.request);
  const [requestor, setRequestor] = useState({});
  const [targetLink, setTargetLink] = useState("");

  const getRequestor = useCallback(
    async (id) => {
      if (id) {
        console.log("Getting requestor for ", id);
        let req = await resolveIdentity(id);
        setRequestor(req.payload.data);
      }
    },
    [resolveIdentity]
  );

  // Set the request data as it changes
  useEffect(() => {
    if (!request && requestData.request) {
      setRequest(requestData.request);
    }
  }, [request, requestData]);

  // Fetch the requestor after setting the request object
  useEffect(() => {
    const fetchOwner = async () => {
      if (request?.requestorId) {
        createTargetLink(request);
        await getRequestor(request.requestorId);
      }
    };
    fetchOwner();
  }, [request, getRequestor]);

  const createTargetLink = (request) => {
    if (request) {
      if (
        request.targetType === "Group" ||
        request.targetType === "IdentityMembership"
      ) {
        setTargetLink(
          `${cfg.sidebar_groups}/group/${request.targetId1}/details`
        );
      }
      if (request.targetType === "Application") {
        setTargetLink(
          `${cfg.sidebar_applications}/manage/${request.targetId1}`
        );
      }
    }
  };

  const goBack = () => {
    history.push("/requests");
  };

  const handleAction = async (actionFn, actionType, reason) => {
    const resp = await actionFn(requestData.request.id, reason);
    if (resp.type === errorAction(actionType)) {
      notification["error"]({
        message: resp.payload.title,
        description: resp.payload.data.message,
      });
    }
    goBack();
  };

  const handleCancel = () => {
    handleAction(cancelRequest, at.CANCEL_REQUEST);
  };

  const handleApprove = (reason) => {
    handleAction(approveRequest, at.APPROVE_REQUEST, reason);
  };

  const handleDeny = (reason) => {
    handleAction(denyRequest, at.DENY_REQUEST, reason);
  };

  return (
    <RequestDetails
      {...props}
      request={
        request
          ? {
              ...request,
              requestorName: requestor.displayName,
              targetLink: targetLink,
            }
          : null
      }
      toApprove={requestData.toApprove}
      onApprove={handleApprove}
      onCancel={handleCancel}
      onDeny={handleDeny}
    />
  );
};

RequestDetailsWrapper.propTypes = {
  requestData: PropTypes.object,
  cancelRequest: PropTypes.func.isRequired,
  approveRequest: PropTypes.func.isRequired,
  denyRequest: PropTypes.func.isRequired,
};

export default withRouter(
  connect(
    (state, router) => ({
      history: router.history,
      requestData: selectFocusedRequest(state, router.match.params.id),
      loading:
        isApiCallLoading(state, at.CANCEL_REQUEST) ||
        isApiCallLoading(state, at.DENY_REQUEST) ||
        isApiCallLoading(state, at.APPROVE_REQUEST),
    }),
    {
      setBreadcrumbs,
      cancelRequest,
      denyRequest,
      approveRequest,
      resolveIdentity,
    }
  )(RequestDetailsWrapper)
);
