import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Segment,
  Table,
  Icon,
  Message,
  Header,
} from "semantic-ui-react";
import { connect } from "react-redux";
import { setBreadcrumbs } from "../../actions/breadcrumbs";
import { getMyOwnedIdentities } from "../../actions/api";
import { types as at } from "@authzsvc/api-lib";
import { selectIdentity, isApiCallLoading } from "../../selectors";
import { LoadingWrapper } from "@authzsvc/common-react-components";
import AccountCell from "./AccountCell";
import _ from "lodash";
import NewAccountModal from "./NewAccountModal";
import { Tooltip } from "antd";
import { IdentityTypes } from "../../constants";

const IdentityListingTable = ({
  identities,
  loading,
  areSecondary,
  pageRefresh,
}) => (
  <LoadingWrapper loading={loading}>
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={1} textAlign="center">
            Login
          </Table.HeaderCell>
          <Table.HeaderCell width={1} textAlign="center">
            Actions
            <Tooltip title="Actions become available once a login is fully created.">
              <Icon color="blue" floated="right" link name="question" />
            </Tooltip>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {identities &&
          identities.map((i, index) => (
            <AccountCell
              identity={i}
              secondary={areSecondary}
              pageRefresh={pageRefresh}
              key={index}
            />
          ))}
      </Table.Body>
    </Table>
  </LoadingWrapper>
);

IdentityListingTable.propTypes = {
  identities: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  secondary: PropTypes.bool,
};

const infoContent = `
You can change and reset passwords for your secondary and service CERN logins, as well as changing the password for your primary account (the one you are currently logged in with).
`;

const OtherAccountsManagement = ({
  // props
  breadcrumbs,
  loading,
  // redux props
  identity,
  // redux actions
  setBreadcrumbs,
  getMyOwnedIdentities,
}) => {
  const [ownedIdentities, setOwnedIdentities] = useState(undefined);
  useEffect(() => {
    setBreadcrumbs(breadcrumbs);
  }, [breadcrumbs, setBreadcrumbs]);

  useEffect(() => {
    const getData = async () => {
      const idResponse = await getMyOwnedIdentities(identity.id);
      if (idResponse && idResponse.payload.data) {
        setOwnedIdentities(idResponse.payload.data);
      }
    };
    if (identity && identity.id && ownedIdentities === undefined) {
      getData();
    }
  }, [identity, setOwnedIdentities, getMyOwnedIdentities, ownedIdentities]);

  const secondaryIdentities = _.filter(
    ownedIdentities,
    (i) => i.type === IdentityTypes.Secondary
  );
  const serviceIdentities = _.filter(
    ownedIdentities,
    (i) => i.type === IdentityTypes.Service
  );

  const pageRefresh = async (deletedIdentityId) => {
    if (deletedIdentityId) {
      const newOwnedIdentities = [...ownedIdentities].filter(
        (i) => i.id !== deletedIdentityId
      );
      setOwnedIdentities(newOwnedIdentities);
    }
    const idResponse = await getMyOwnedIdentities(identity.id);
    if (idResponse && idResponse.payload.data) {
      setOwnedIdentities(idResponse.payload.data);
    }
  };

  return (
    <Container>
      <div style={{ position: "relative", zIndex: 1 }}>
        <Message content={infoContent} />
      </div>

      <Segment basic>
        <div style={{ position: "relative", zIndex: 1, marginBottom: "40px" }}>
          <NewAccountModal identity={identity} onClose={pageRefresh} />
        </div>

        <Segment color="blue">
          <Header as="h3">
            <Icon style={{ fontSize: "26px" }} name="user" />
            Primary login
          </Header>
          <IdentityListingTable
            loading={loading}
            identities={[identity]}
            areSecondary={false}
            identity={identity}
          />
        </Segment>

        {identity.type === "Person" && (
          <>
            <Segment color="blue">
              <Header as="h3">
                <Icon style={{ fontSize: "26px" }} name="group" />
                Secondary logins
              </Header>
              <IdentityListingTable
                loading={loading}
                identities={secondaryIdentities}
                areSecondary={true}
                pageRefresh={pageRefresh}
                identity={identity}
              />
            </Segment>
            <Segment color="blue">
              <Header as="h3">
                <Icon style={{ fontSize: "26px" }} name="cogs" />
                Service logins
              </Header>
              <IdentityListingTable
                loading={loading}
                identities={serviceIdentities}
                areSecondary={true}
                pageRefresh={pageRefresh}
                identity={identity}
              />
            </Segment>
          </>
        )}
      </Segment>
    </Container>
  );
};

OtherAccountsManagement.propTypes = {
  breadcrumbs: PropTypes.array.isRequired,
};

export default connect(
  (state) => ({
    identity: selectIdentity(state),
    loading:
      isApiCallLoading(state, at.GET_OWNED_IDENTITIES) ||
      isApiCallLoading(state, at.GET_IDENTITY),
  }),
  {
    setBreadcrumbs,
    getMyOwnedIdentities,
  }
)(OtherAccountsManagement);
