import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Modal,
  Form,
  Button,
  Icon,
  Checkbox,
  Segment,
  Header,
  List,
  Grid,
} from "semantic-ui-react";
import PasswordValidTick, { validatePassword } from "./PasswordValidTick";
import PasswordMatchTick from "./PasswordMatchTick";
import { Tooltip } from "antd";
import { useIsDesktop } from "../../../hooks";

const ResetPasswordModal = ({ identity, onSubmit, title, loading }) => {
  const [open, setOpen] = useState(false);
  const [passwordError, setPasswordError] = useState(null);

  const close = () => {
    setNewPassword("");
    setConfirmNewPassword("");
    setOpen(false);
    setConfirmRules(false);
    setPasswordError(null);
  };

  const submit = async () => {
    const resp = await onSubmit(newPassword);
    if (passwordSubmissionErrors(resp)) {
      close();
    }
  };

  const [activePolicy, setActivePolicy] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [confirmRules, setConfirmRules] = useState(false);
  const isDesktop = useIsDesktop();

  const handleChange = (e, { name, value }) => {
    setPasswordError(null);
    if (name === "newPassword") {
      setNewPassword(value);
      setActivePolicy("8-chars");
      // DISABLED
      // if (value.length >= 20) {
      //   setActivePolicy("20-chars");
      // } else {
      //   setActivePolicy("8-chars");
      // }
    } else if (name === "confirmNewPassword") {
      setConfirmNewPassword(value);
    }
  };

  const passwordSubmissionErrors = (value) => {
    if (value.payload.data && value.payload.data.errors) {
      if (value.payload.data.errors.compromised_password) {
        setPasswordError(value.payload.data.errors.compromised_password);
      }
      if (value.payload.data.errors.display_name_found) {
        setPasswordError(value.payload.data.errors.display_name_found);
      }
      if (value.payload.data.errors.username_found) {
        setPasswordError(value.payload.data.errors.username_found);
      }
      return false;
    } else {
      return true;
    }
  };
  const RestrictionsMessage = () => (
    <List className="password-policy-margin-top">
      <Header as="h4">Password requirements:</Header>
      <List.Item>
        {activePolicy === "8-chars" ? (
          <Icon className="active-policy" name="chevron circle right" />
        ) : (
          <Icon name="chevron circle right" />
        )}
        <List.Content>
          <List.Description>
            At least 8 characters long, with character restrictions.
          </List.Description>
        </List.Content>
      </List.Item>
      {/* DISABLED
                    <List.Item>
                      {activePolicy === "20-chars" ? (
                        <Icon
                          className="active-policy"
                          name="chevron circle right"
                        />
                      ) : (
                        <Icon name="chevron circle right" />
                      )}
                      <List.Content>
                        <List.Description>
                          At least 20 characters long, with no restrictions.
                        </List.Description>
                      </List.Content>
                    </List.Item> */}
    </List>
  );
  return (
    <Modal
      size="small"
      closeOnEscape={false}
      open={open}
      closeOnDimmerClick={false}
      trigger={
        <Tooltip title={title}>
          <Button
            labelPosition="left"
            icon
            fluid
            onClick={() => setOpen(true)}
            primary
          >
            <Icon name="redo" /> {title}
          </Button>
        </Tooltip>
      }
    >
      <Modal.Header>
        <Icon name="redo" loading={loading} /> {title} for account:{" "}
        <b>{identity.upn}</b>
      </Modal.Header>
      <Modal.Content>
        <Segment basic>
          <Form>
            <Grid columns="equal" divided>
              <Grid.Row>
                <Grid.Column>
                  <Form.Group>
                    <Form.Input
                      label="New password"
                      type="password"
                      name="newPassword"
                      id="resetNewPasswordField"
                      value={newPassword}
                      onChange={handleChange}
                      error={passwordError}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Input
                      label="Confirm new password"
                      type="password"
                      name="confirmNewPassword"
                      id="resetConfirmNewPasswordField"
                      value={confirmNewPassword}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  {!isDesktop && <RestrictionsMessage />}
                </Grid.Column>
                {isDesktop && (
                  <Grid.Column className="policies">
                    <RestrictionsMessage />
                  </Grid.Column>
                )}
              </Grid.Row>
            </Grid>
            <PasswordValidTick
              setNewPasswordError={setPasswordError}
              newPassword={newPassword}
            />
            <PasswordMatchTick
              newPassword={newPassword}
              confirmNewPassword={confirmNewPassword}
            />
            <Form.Group>
              <Form.Field className="password-oc5-margin">
                <Checkbox
                  onChange={() => setConfirmRules(!confirmRules)}
                  label={
                    <label>
                      I confirm that I have read, understood and will abide by
                      the{" "}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://security.web.cern.ch/rules/en/index.shtml"
                      >
                        CERN Computing Rules (OC5){" "}
                      </a>
                      *
                    </label>
                  }
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </Segment>
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={close}
          disabled={loading}
          loading={loading}
          negative
          icon="cancel"
          content="Cancel"
          labelPosition="left"
        />
        <Button
          loading={loading}
          onClick={submit}
          disabled={
            newPassword !== confirmNewPassword ||
            !newPassword ||
            !confirmNewPassword ||
            !confirmRules ||
            loading ||
            !validatePassword(newPassword)
          }
          positive
          icon="checkmark"
          content="Confirm"
          labelPosition="left"
        />
      </Modal.Actions>
    </Modal>
  );
};

ResetPasswordModal.propTypes = {
  identity: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default ResetPasswordModal;
