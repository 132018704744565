import React from "react";
import { Container, Header, Icon, Segment } from "semantic-ui-react";
import SSPRError from "./SSPRError";

const SSPRWrapper = ({ children }) => {
  return (
    <Segment basic>
      <Container>
        <SSPRError />
        <Segment>
          <Header as={"h2"}>
            <Icon name="user outline" /> CERN Primary Account Self-Service
            Password Reset
          </Header>
          {children}
        </Segment>
      </Container>
    </Segment>
  );
};

export default SSPRWrapper;
