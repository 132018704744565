import { LoadingWrapper } from "@authzsvc/common-react-components";
import PropTypes from "prop-types";
import { getIdentityById } from "../../actions/api";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Popup, Icon } from "semantic-ui-react";

/**
 * Gets the Identity based on an ID and displays him/her
 */
class ResolvedIdentity extends Component {
  state = {
    isLoading: false,
    identity: {},
  };

  async componentDidMount() {
    this.setState({ isLoading: true });
    const identity = await this.props.getIdentityById(this.props.id);

    if (identity) {
      this.setState({
        identity: identity.payload.data,
      });
    }
    this.setState({
      isLoading: false,
    });
  }

  render() {
    const { isLoading, identity } = this.state;
    return (
      <LoadingWrapper loading={isLoading}>
        {identity && (
          <Popup
            key={identity.id}
            trigger={
              <span style={{ cursor: "pointer" }}>{identity.displayName}</span>
            }
            header={
              <h3>
                <Icon name="user outline" /> {identity.displayName}
              </h3>
            }
            content={
              <>
                <div>Main login: {identity.upn}</div>
              </>
            }
          />
        )}
      </LoadingWrapper>
    );
  }
}

ResolvedIdentity.propTypes = {
  id: PropTypes.string.isRequired,
  getIdentityById: PropTypes.func.isRequired,
};

export default connect((state) => ({}), { getIdentityById })(ResolvedIdentity);
