import { IdentityTypes } from "./constants";

export const isApiCallLoading = (state, requestType) => {
  return state.loading[requestType];
};

export const selectIdentity = (state) => {
  return state.identity.my;
};

export const selectAccountManagementOn = (capabilities) => {
  return capabilities && capabilities.account_management_on
    ? capabilities.account_management_on
    : false;
};

export const selectIdentitySearch = (state) => {
  return state.identity.search;
};

export const getGroups = (state) => {
  return state.groups;
};

export const selectGroupById = (state, id) => {
  return state.groups.find((g) => g.id === id);
};

export const selectFocusedRequest = (state, id) => {
  let request = state.requests.toApprove.find((r) => r.id === id);
  if (request) {
    return {
      request,
      toApprove: true,
    };
  } else {
    return {
      request: state.requests.my.find((r) => r.id === id),
      toApprove: false,
    };
  }
};

export const selectAccountById = (state, id) => {
  return state.accounts.accounts.find((a) => a.id === id);
};

export const selectAccounts = (state) => {
  return state.accounts.accounts;
};

export const selectBreadcrumbs = (state) => {
  return state.breadcrumbs.nav;
};

export const selectAccountProviders = (state) => {
  return state.accountProviders;
};

export const selectCernAccountProvider = (state) => {
  return state.accountProviders.find((ac) => ac.displayName === "CERN");
};

export const selectMfaSettings = (state) => state.mfaCredentials;

export const userTokenHasRole = (state, role) => {
  if (
    state.keycloak.instance &&
    state.keycloak.instance.tokenParsed &&
    state.keycloak.instance.tokenParsed.cern_roles
  ) {
    return state.keycloak.instance.tokenParsed.cern_roles.includes(role);
  }
  console.warn("No 'cern_roles' claim found in the Keycloak token!");
  return false;
};

export const isCernPerson = (state) => {
  return (
    state.identity.my.type === IdentityTypes.Person &&
    state.identity.my.source === "cern"
  );
};

export const isCernAccount = (state) => {
  return (
    isCernPerson(state) ||
    state.identity.my.type === IdentityTypes.Secondary ||
    state.identity.my.type === IdentityTypes.Service
  );
};

/**
 * Returns true if the primary account of this identity is provided by CERN.
 * Used for MFA settings configuration
 * @param  {[type]}  state [description]
 * @return {Boolean}       [description]
 */
export const isPrimaryAccountCern = (state) => {
  const { accounts, accountProviders } = state;
  const primaryAccount = accounts.primaryAccount;
  if (!primaryAccount || !accountProviders) {
    return false;
  }
  const provider = accountProviders.find(
    (a) => a.id === primaryAccount.accountProviderId
  );
  if (!provider) {
    return false;
  }
  return provider.loaValue === 5;
};

export const getSSPRError = (state) => state.selfServiceReset.error;
