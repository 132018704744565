import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store, { history } from "./store";
import * as serviceWorker from "./serviceWorker";
import { ConnectedRouter } from "connected-react-router";
import "semantic-ui-cern/dist/semantic.min.css";
import "antd/dist/antd.css";
import AppWrapper from "./AppWrapper";
import HeaderMenuItems from "./components/headers/HeaderMenuItems";
import { CernFooter } from "@authzsvc/common-react-components";
import styles from "./styles.module.scss";

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <div className={styles.container}>
        <HeaderMenuItems />
        <div className={styles.content}>
          <AppWrapper />
        </div>
        <div className={styles.footer}>
          <CernFooter />
        </div>
      </div>
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
