import React from "react";
import PropTypes from "prop-types";
import { Segment } from "semantic-ui-react";

const BlockedResource = ({ resource }) =>
  resource && resource.blocked ? (
    <Segment color="red">
      <p>
        This resource was blocked on "
        {resource.blockingTime
          ? new Date(resource.blockingTime).toLocaleString()
          : "--"}
        " for the reason: <b>"{resource.blockingReason}"</b>
      </p>
    </Segment>
  ) : (
    <></>
  );

BlockedResource.propTypes = {
  resource: PropTypes.object,
};

export default BlockedResource;
