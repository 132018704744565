import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setBreadcrumbs } from "../../actions/breadcrumbs";
import { Header, Segment, Icon, Container } from "semantic-ui-react";
import { ContentTable, SearchBar } from "@authzsvc/common-react-components";
import { types as at } from "@authzsvc/api-lib";
import { getGroups, isApiCallLoading } from "../../selectors";
import { getMyGroups, searchForGroup } from "../../actions/api";
import { sidebar_groups } from "../../config";

class MyGroups extends React.Component {
  componentDidMount() {
    this.props.setBreadcrumbs(this.props.breadcrumbs);
    this.props.getMyGroups({
      sort: "displayName:asc",
    });
  }

  handleSearch = (searchValue, searchType) => {
    if (searchValue.length >= 2) {
      this.props.getMyGroups({
        filter: `groupIdentifier:${searchType}:${searchValue}`,
        sort: "groupIdentifier",
      });
    } else {
      this.props.getMyGroups({
        sort: "groupIdentifier:asc",
      });
    }
  };

  render() {
    const columns = [
      {
        title: "Name",
        dataIndex: "displayName",
        key: "displayName",
        ellipsis: true,
        searchable: true,
        render: (_, group) => (
          <a
            href={`${sidebar_groups}/group/${group.groupIdentifier}/details`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {group.groupIdentifier}
          </a>
        ),
      },
      {
        title: "Identifier",
        dataIndex: "groupIdentifier",
        key: "groupIdentifier",
        ellipsis: true,
        searchable: true,
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => a.groupIdentifier.localeCompare(b.groupIdentifier),
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
        ellipsis: true,
        searchable: true,
      },
    ];

    const { loading, groups } = this.props;
    const groupsWithKey = groups.map((group) => ({
      ...group,
      key: group.id,
    }));

    return (
      <Container>
        <Segment color="blue">
          <Header as="h2">
            <Icon name="users" /> My Groups: {this.props.groups.length}
          </Header>
          <SearchBar
            fieldDisplayName="Groups identifier"
            onSearch={this.handleSearch}
          />

          <ContentTable
            loading={loading}
            rowkey={"groupIdentifier"}
            dataSource={groupsWithKey}
            columns={columns}
          />
        </Segment>
      </Container>
    );
  }
}

MyGroups.propTypes = {
  getMyGroups: PropTypes.func.isRequired,
  groups: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default connect(
  (state, router) => ({
    groups: getGroups(state),
    loading: isApiCallLoading(state, at.GET_MY_GROUPS),
    history: router.history,
  }),
  { getMyGroups, setBreadcrumbs, searchForGroup }
)(MyGroups);
