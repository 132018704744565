import React from "react";
import PropTypes from "prop-types";
import { Icon, Message } from "semantic-ui-react";

const BlockedForSecurityIssues = ({ identity }) => (
  <Message error icon>
    <Icon name="ban" />
    <Message.Header>Account blocked for security issues!</Message.Header>
    <p>
      This account was blocked for security reasons and can only be reactivated
      by the CERN Security Team. The blocking reason is: "
      <b>{identity.blockingReason}</b>"
    </p>
  </Message>
);

BlockedForSecurityIssues.propTypes = {
  identity: PropTypes.object.isRequired,
};

export default BlockedForSecurityIssues;
