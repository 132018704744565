import { CLEAR_SSPR_ERROR, SSPR_ERROR } from "../actions/hookActions";

const defaultState = {
  error: "",
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SSPR_ERROR:
      const message =
        action.err && action.err.message
          ? action.err.message
          : "Please contact Service Desk!";
      return { ...state, error: message };
    case CLEAR_SSPR_ERROR:
      return { ...state, error: "" };
    default:
      return state;
  }
};
