import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Icon,
  Header,
  Form,
  Button,
  Card,
  Checkbox,
  List,
  Segment,
} from "semantic-ui-react";
import { connect } from "react-redux";
import {
  searchForIdentity,
  createServiceLogin,
  createSecondaryLogin,
  searchMailPropertiesIdentifier,
} from "../../actions/api";
import { Tooltip, Modal } from "antd";
import { withApiCapabilities } from "../../wrappers";
import { selectAccountManagementOn } from "../../selectors";
import { IdentityTypes } from "../../constants";

const SecondaryCardDescription = () => (
  <Card.Description>
    A Secondary Login:
    <List bulleted>
      <List.Item>Is personal</List.Item>
      <List.Item>Cannot be transferred to another person</List.Item>
      <List.Item>Has no mailbox (emails are redirected to the owner)</List.Item>
      <List.Item>
        When you leave CERN, your Secondary accounts will be blocked and then
        deleted (after a grace period)
      </List.Item>
    </List>
    <b>Choose a Secondary Login if you need:</b>
    <List bulleted>
      <List.Item>
        An alternative account with administrative privileges
      </List.Item>
      <List.Item>A login for testing</List.Item>
    </List>
  </Card.Description>
);

const ServiceCardDescription = () => (
  <Card.Description>
    A Service Login:
    <List bulleted>
      <List.Item>Is an official resource</List.Item>
      <List.Item>Can be reassigned to another person</List.Item>
      <List.Item>
        Has its own mailbox. Emails are forwarded to the owner by default, but
        forwarding rules can be reconfigured later
      </List.Item>
      <List.Item>
        When you leave CERN, your Service accounts will be automatically
        reassigned to your Team Leader or Supervisor
      </List.Item>
    </List>
    <b>Choose a Service Login if you need:</b>
    <List bulleted>
      <List.Item>Alternative credentials for a software service</List.Item>
      <List.Item>A mailbox for a service or a club</List.Item>
    </List>
  </Card.Description>
);

const IdentityCreationCard = ({ onSelect, selected, header, description }) => {
  return (
    <Card
      fluid
      onClick={onSelect}
      color={selected ? "blue" : "white"}
      style={getStyleForSelectedCard(selected)}
    >
      <Card.Content>
        <Card.Header>{header}</Card.Header>
        {description}
      </Card.Content>
    </Card>
  );
};

const VIEWS = {
  ACCOUNT_CHOICE: "ACCOUNT_CHOICE",
  IDENTITY_FORM: "IDENTITY_FORM",
};

const getStyleForSelectedCard = (selected) => {
  if (selected) {
    return {
      boxShadow: "0 0 0 1px #d4d4d5,0 2px 0 0 #2185d0,0 1px 3px 0 #d4d4d5",
    };
  }
  return { boxShadow: "none" };
};

const NewAccountModal = ({
  identity,
  compact,
  accountManagementOn,
  //Action to perform on closing
  onClose,
  // API actions
  searchForIdentity,
  createServiceLogin,
  createSecondaryLogin,
  searchMailPropertiesIdentifier,
}) => {
  const [modalView, setModalView] = useState(VIEWS["ACCOUNT_CHOICE"]);
  const [identityType, setIdentityType] = useState(IdentityTypes.Secondary);
  const [identityMail, setIdentityMail] = useState("");
  const [identityUpn, setIdentityUpn] = useState("");
  const [identityDescription, setIdentityDescription] = useState("");
  const [identityDisplayName, setIdentityDisplayName] = useState("");
  const [identityUpnError, setIdentityUpnError] = useState(null);
  const [identityDisplayNameError, setIdentityDisplayNameError] =
    useState(null);
  const [identityMailError, setIdentityMailError] = useState(null);
  const [open, setOpen] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [secondaryCheckboxChecked, setSecondaryCheckboxChecked] =
    useState(true);
  const [serviceCheckboxChecked, setServiceCheckboxChecked] = useState(false);

  const close = () => {
    setOpen(false);
    setIdentityMail("");
    setIdentityDisplayName("");
    setIdentityUpn("");
    setIdentityDescription("");
    setIdentityUpnError(null);
    setIdentityDisplayNameError(null);
    setIdentityMailError(null);
    setModalView("ACCOUNT_CHOICE");
    setProcessing(false);
    onClose();
  };

  const next = () => setModalView(VIEWS["IDENTITY_FORM"]);
  const back = () => setModalView(VIEWS["ACCOUNT_CHOICE"]);
  const emailRequired = identityType === IdentityTypes.Service;

  const handleSetIdentityDisplayName = (e, { value }) => {
    validateIdentityDisplayName(value);
    setIdentityDisplayName(value);
  };
  const handleSetIdentityUpn = (e, { value }) => {
    validateIdentityUpn(value);
    setIdentityUpn(value);
  };
  const handleSetIdentityMail = (e, { value }) => {
    validateIdentityMail(value);
    setIdentityMail(value);
  };
  const handleSetIdentityDescription = (e, { value }) =>
    setIdentityDescription(value);

  const validateIdentityUpn = (value) => {
    if (!value) {
      setIdentityUpnError({
        content: "Login required",
        pointing: "below",
      });
      setProcessing(false);
      return false;
    } else if (!value.match(/^[a-zA-Z0-9]{3,15}$/)) {
      setIdentityUpnError({
        content: "Login must be 3 - 15 characters, letters and numbers only",
        pointing: "below",
      });
      return false;
    } else {
      setIdentityUpnError(null);
      return true;
    }
  };

  const validateIdentityDisplayName = (value) => {
    if (!value) {
      setIdentityDisplayNameError({
        content: "Display name required",
        pointing: "below",
      });
      return false;
    } else if (!value.match(/^[a-zA-Z][a-zA-Z0-9 ]*[ ][a-zA-Z0-9 ]+$/)) {
      setIdentityDisplayNameError({
        content:
          "Display name must start with a letter, have at least one space and contain letters and numbers only",
        pointing: "below",
      });
      return false;
    } else {
      setIdentityDisplayNameError(null);
      return true;
    }
  };

  const validateIdentityMail = (value) => {
    if (!emailRequired) {
      setIdentityMailError(null);
      return true;
    } else if (!value && emailRequired) {
      setIdentityMailError({
        content: "Email required",
        pointing: "below",
      });
      setProcessing(false);
      return false;
    } else if (!value.match(/^[a-zA-Z0-9.]+[.][a-zA-Z0-9.]+(@cern\.ch)$/)) {
      setIdentityMailError({
        content: "Email must contain at least 1 dot and be at domain cern.ch",
        pointing: "below",
      });
      setProcessing(false);
      return false;
    } else {
      setIdentityMailError(null);
      return true;
    }
  };

  const validateForm = async () => {
    //Check that upn isn't already used
    const identityDupCheck = await searchForIdentity(identityUpn);
    const mailDupCheck = await searchMailPropertiesIdentifier("alias", [
      identityMail,
    ]);
    if (
      mailDupCheck.payload &&
      mailDupCheck.payload.data &&
      identityDupCheck.payload
    ) {
      const mailAliasIsTaken = mailDupCheck.payload.data.find(
        (o) => o.value === identityMail
      ).isTaken;
      const identityIsTaken = identityDupCheck.payload.find(
        (e) => e.upn === identityUpn
      );
      if (mailAliasIsTaken || identityIsTaken) {
        if (mailAliasIsTaken) {
          setIdentityMailError({
            content: "This email is already in use",
            pointing: "below",
          });
        }
        if (identityIsTaken) {
          setIdentityUpnError({
            content: "This login is already in use",
            pointing: "below",
          });
        }
        return false;
      }
    }

    // Run through the other checks
    var checks = [];
    checks.push(validateIdentityUpn(identityUpn));
    checks.push(validateIdentityDisplayName(identityDisplayName));
    checks.push(validateIdentityMail(identityMail));
    return !checks.includes(false);
  };

  const handleSubmit = async () => {
    setProcessing(true);

    var checkForm = await validateForm();
    if (!checkForm) {
      setProcessing(false);
      return;
    }
    switch (identityType) {
      case IdentityTypes.Secondary:
        await createSecondaryLogin(identity.id, {
          email: identityUpn + "@cern.ch",
          displayName: identityDisplayName,
          description: identityDescription,
          upn: identityUpn,
        });
        break;
      case IdentityTypes.Service:
        await createServiceLogin(identity.id, {
          email: identityMail ? identityMail : identityUpn + "@cern.ch",
          displayName: identityDisplayName,
          description: identityDescription,
          upn: identityUpn,
        });
        break;
      default:
        break;
    }
    close();
    return;
  };

  const accountTypeChoiceContent = (
    <Segment basic>
      <Card.Group fluid centred itemsPerRow={1}>
        <Checkbox
          label="A Secondary Login:"
          checked={secondaryCheckboxChecked}
          onChange={() => {
            setSecondaryCheckboxChecked(true);
            setServiceCheckboxChecked(false);
            setIdentityType(IdentityTypes.Secondary);
          }}
        ></Checkbox>
        <IdentityCreationCard
          onSelect={() => {
            setIdentityType(IdentityTypes.Secondary);
            setSecondaryCheckboxChecked(true);
            setServiceCheckboxChecked(false);
          }}
          description={<SecondaryCardDescription />}
          header={
            <>
              <Icon name="group" /> Secondary
            </>
          }
          selected={identityType === IdentityTypes.Secondary}
        />
        <Checkbox
          label="A Service Login"
          checked={serviceCheckboxChecked}
          onChange={() => {
            setServiceCheckboxChecked(true);
            setSecondaryCheckboxChecked(false);
            setIdentityType(IdentityTypes.Service);
          }}
        ></Checkbox>
        <IdentityCreationCard
          onSelect={() => {
            setIdentityType(IdentityTypes.Service);
            setServiceCheckboxChecked(true);
            setSecondaryCheckboxChecked(false);
          }}
          description={<ServiceCardDescription />}
          header={
            <>
              <Icon name="cogs" /> Service
            </>
          }
          selected={identityType === IdentityTypes.Service}
        />
      </Card.Group>
    </Segment>
  );

  const identityForm = (
    <Segment basic>
      {/* TODO The width is behaving strangely on screen resize */}
      <Form style={{ width: "600px" }} id="identity-form">
        <Form.Input
          required
          autoFocus={true}
          error={identityUpnError}
          label="Login"
          placeholder='The account login (3-15 chars, letters and numbers only), e.g. "service1account"'
          name="identityUpn"
          aria-label="Enter a login name"
          id="identityUpnField"
          value={identityUpn}
          onChange={handleSetIdentityUpn}
        />
        <Form.Input
          required
          error={identityDisplayNameError}
          label="Display name"
          placeholder='A friendly name for the account (must contain a space), e.g. "Service1 Account"'
          name="identityDisplayName"
          aria-label="Enter a name"
          id="identityDisplayNameField"
          value={identityDisplayName}
          onChange={handleSetIdentityDisplayName}
        />
        <Form.Input
          required
          label="Description"
          placeholder="Brief description of the purpose of the account"
          aria-label="Enter a description"
          name="identityDescription"
          id="identityDescriptionField"
          value={identityDescription}
          onChange={handleSetIdentityDescription}
        />
        {emailRequired ? (
          <Form.Input
            required="true"
            error={identityMailError}
            label="Email"
            placeholder="Main mail address of the account (must contain a dot), e.g. service.1@cern.ch"
            name="identityMail"
            id="identityMailField"
            value={identityMail}
            onChange={handleSetIdentityMail}
          />
        ) : (
          <></>
        )}
      </Form>
    </Segment>
  );

  return (
    <>
      {accountManagementOn && (
        <>
          <Tooltip title="Create Service or Secondary Login">
            <Button
              icon
              labelPosition="left"
              className="primary"
              floated="right"
              onClick={() => setOpen(true)}
            >
              <Icon name="plus" />
              {!compact && <> Create Login</>}
            </Button>
          </Tooltip>
          <Modal
            centered
            width={700}
            as={Form}
            visible={open}
            onCancel={close}
            title={
              <Header>
                <Icon name="user" />
                Create a new Login
              </Header>
            }
            footer={
              <>
                <Button
                  onClick={close}
                  disabled={processing}
                  negative
                  icon="cancel"
                  content="Cancel"
                />
                {modalView === VIEWS["ACCOUNT_CHOICE"] ? (
                  <Button
                    onClick={next}
                    color="blue"
                    icon="angle right"
                    content="Next"
                  />
                ) : (
                  <>
                    <Button
                      onClick={back}
                      disabled={processing}
                      color="blue"
                      icon="angle left"
                      content="Back"
                    />
                    <Button
                      type="submit"
                      onClick={handleSubmit}
                      loading={processing}
                      disabled={processing}
                      color="green"
                      icon="checkmark"
                      content="Confirm"
                      form="identity-form"
                    />
                  </>
                )}
              </>
            }
          >
            <Segment basic>
              {modalView === VIEWS["ACCOUNT_CHOICE"]
                ? accountTypeChoiceContent
                : identityForm}
            </Segment>
          </Modal>
        </>
      )}
    </>
  );
};

NewAccountModal.propTypes = {
  identity: PropTypes.object.isRequired,
  compact: PropTypes.object,
};

export default withApiCapabilities(
  connect(
    (state, ownProps) => ({
      accountManagementOn: selectAccountManagementOn(ownProps.capabilities),
    }),
    {
      searchForIdentity,
      createServiceLogin,
      createSecondaryLogin,
      searchMailPropertiesIdentifier,
    }
  )(NewAccountModal)
);
