import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { getRequestsToApprove } from "../../actions/api";
import { isApiCallLoading } from "../../selectors";
import _ from "lodash";
import { RequestsHeader } from "@authzsvc/common-react-components";

const RequestsHeaderButton = ({
  // state
  loading,
  requests,
  // actions
  getRequestsToApprove,
}) => {
  useEffect(() => {
    getRequestsToApprove();
  }, [getRequestsToApprove]);

  return (
    <RequestsHeader link={"/requests"} loading={loading} requests={requests} />
  );
};

RequestsHeaderButton.propTypes = {
  getRequestsToApprove: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};
export default withRouter(
  connect(
    (state) => ({
      loading: isApiCallLoading(state, "getRequestsToApprove"),
      requests: _.filter(
        state.requests.toApprove,
        (p) => p.status === "Pending"
      ),
    }),
    {
      getRequestsToApprove,
    }
  )(RequestsHeaderButton)
);
