import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Segment,
  Container,
  Header,
  List,
  Divider,
  Button,
  Form,
  Message,
  Icon,
} from "semantic-ui-react";
import PropTypes from "prop-types";
import TooltipInfoIcon from "../common/TooltipInfoIcon";

import AccountListItem from "./AccountListItem";
import { types as at, successAction, errorAction } from "@authzsvc/api-lib";
import {
  isApiCallLoading,
  selectAccounts,
  isCernPerson,
} from "../../selectors";
import {
  getAccounts,
  associateIdentity,
  getCurrentPrimaryAccount,
  setPrimaryAccount,
  associateSocialIdentity,
  deleteAccountFromIdentity,
} from "../../actions/api";
import { LoadingWrapper } from "@authzsvc/common-react-components";
import { setBreadcrumbs } from "../../actions/breadcrumbs";
import _ from "lodash";

import "./Accounts.css";

import Keycloak from "keycloak-js";
import { notification } from "antd";
import {
  keycloakUrl,
  keycloakSocialRealm,
  keycloakClientId,
  keycloakSocialRealmUsernameClaim,
} from "../../config";

const silentSsoCheckUrl = `${window.location.origin}/check-sso-silent.html`;

const getSocialAccountAssociatedMessage = () => (
  <Message warning>
    <Icon name="warning sign" />
    The external account you are currently logged in with seems to already be
    linked with your identity. Please log out and use another account if you
    wish to link it to your other accounts.
  </Message>
);

class Accounts extends Component {
  state = {
    loading: false,
    socialClientLoading: true,
    socialClient: new Keycloak({
      url: keycloakUrl,
      realm: keycloakSocialRealm,
      clientId: keycloakClientId,
    }),
    userInfo: {},
  };

  async componentDidMount() {
    this.props.setBreadcrumbs(this.props.breadcrumbs);
    this.state.socialClient
      .init({
        promiseType: "native",
        onLoad: "check-sso",
        silentCheckSsoRedirectUri: silentSsoCheckUrl,
        silentCheckSsoFallback: false,
        flow: "implicit",
      })
      .then(async (authenticated) => {
        console.log("Keycloak social realm authenticated: ", authenticated);
        if (authenticated) {
          const userInfo = await this.state.socialClient.loadUserInfo();
          const userProfile = await this.state.socialClient.loadUserProfile();
          if (userProfile.attributes && userProfile.attributes.federationLink) {
            userInfo.federationLink = userProfile.attributes.federationLink[0];
          }
          this.setState({
            userInfo: userInfo,
          });
        }
        this.setState({ socialClientLoading: false });
      })
      .catch(() => {
        this.setState({ socialClientLoading: false });
        console.error("Error logging in to 'social' realm!");
      });

    await this.loadData();
  }

  loadData = async () => {
    this.props.getAccounts();
    this.props.getCurrentPrimaryAccount();
  };

  logoutWithDelay = (delay) => {
    // Remove any additional params from the current URL, this cause an error with logout otherwise
    this.props.history.push(`/account-linking`);
    setTimeout(() => this.state.socialClient.logout(), delay);
  };

  /**
   * Verifies whether the external account is already associated to the current identity's accounts
   */
  socialAccountAlreadyAssociated = () => {
    const { userInfo } = this.state;
    const { accounts } = this.props;
    if (userInfo[keycloakSocialRealmUsernameClaim]) {
      if (
        accounts.find(
          (a) =>
            a.uniqueIdentifier === userInfo[keycloakSocialRealmUsernameClaim]
        )
      ) {
        return true;
      }
    }
    return false;
  };

  /* Associate function for non-social accounts */
  associate = async (selected) => {
    this.setState({ loading: true });
    const action = await this.props.associateIdentity(selected.id);
    if (action && action.payload) {
      // approval required
      notification["warning"]({
        message: "Approval required from the current owner",
        description:
          "The owner or administrator of the identity have been notified.",
      });
    } else if (action && successAction(at.ASSOCIATE_IDENTITY) === action.type) {
      notification["success"]({
        message: "Linking successful",
        description: "The identity has been successfully linked to yours.",
      });
    } else if (action.type === errorAction(at.TRANSFER_IDENTITY)) {
      notification["error"]({
        message: action.payload.title,
        description: action.payload.data.message,
      });
    }
    this.props.getAccounts();
    this.setState({ loading: false });
  };

  isPrimaryAccount = (account) => {
    const { primaryAccount } = this.props;
    if (primaryAccount && account) {
      return primaryAccount.id === account.id;
    }
    return false;
  };

  isDisabledAssignButton = (account) => {
    const { primaryAccount, accountProviders } = this.props;
    if (primaryAccount && account) {
      const primaryAccountProv = accountProviders.find(
        (a) => a.id === primaryAccount.accountProviderId
      );
      const accountProv = accountProviders.find(
        (a) => a.id === account.accountProviderId
      );
      if (primaryAccountProv && accountProv) {
        return primaryAccountProv.loaValue > accountProv.loaValue;
      }
    }
    return false;
  };

  setPrimaryAccount = async (accountId) => {
    const primaryResp = await this.props.setPrimaryAccount(accountId);
    if (primaryResp.type === errorAction(at.TRANSFER_IDENTITY)) {
      notification["error"]({
        message: primaryResp.payload.title,
        description: primaryResp.payload.data.message,
      });
    }
    await this.loadData();
  };

  handleUnlink = async (accountId) => {
    this.setState({ loading: true });
    const action = await this.props.deleteAccountFromIdentity(accountId);
    if (
      action &&
      successAction(at.DELETE_ACCOUNT_FROM_IDENTITY) === action.type
    ) {
      notification["success"]({
        message: "Unlinking successful",
        description: "The account has been removed from your Identity.",
      });
    } else if (action.type === errorAction(at.DELETE_ACCOUNT_FROM_IDENTITY)) {
      notification["error"]({
        message: action.payload.title,
        description: action.payload.data.message,
      });
    }
    this.props.getAccounts();
    this.setState({ loading: false });
  };

  loginSocialAccount = async () => {
    this.state.socialClient.login({
      promiseType: "native",
      onLoad: "login-required",
      flow: "implicit",
      redirectUri: `${window.location.origin}/account-linking`,
    });
  };

  associateSocialAccount = async () => {
    const { socialClient } = this.state;
    const { associateSocialIdentity } = this.props;
    if (socialClient.token) {
      var action = await associateSocialIdentity(socialClient.token);
      if (
        action &&
        successAction(at.ASSOCIATE_SOCIAL_IDENTITY) === action.type
      ) {
        notification["success"]({
          message: "Linking successful",
          description:
            "The identity has been successfully linked to yours. You will be logged out from your social account in a few moments.",
          duration: 3000,
        });
        this.logoutWithDelay(3000);
      } else {
        if (action.type === errorAction(at.ASSOCIATE_SOCIAL_IDENTITY)) {
          notification["error"]({
            message: action.payload.title,
            description: action.payload.data.message,
          });
        }
        this.logoutWithDelay(6000);
      }
    }
  };

  render() {
    const { loading } = this.state;
    const { accountProviders } = this.props;
    const mappedAccounts = _.chain(this.props.accounts)
      .map((acc) => {
        const accountAccProvider = _.find(
          accountProviders,
          (ap) => ap.id === acc.accountProviderId
        );
        if (accountAccProvider) {
          acc.accountProviderName = accountAccProvider.displayName;
          acc.accountProviderLoa = accountAccProvider.loaValue;
        }
        return acc;
      })
      .orderBy(["accountProviderLoa"], ["desc"])
      .value();

    const socialAccountSegment = (
      <Segment>
        <Header as="h3" icon="cog" content="External account actions" />
        {this.socialAccountAlreadyAssociated() &&
          getSocialAccountAssociatedMessage()}
        <div>
          <p>
            You will link your <b>{this.state.userInfo.federationLink}</b>{" "}
            account{" "}
            <b>
              {this.state.userInfo.name} (
              {this.state.userInfo.preferred_username})
            </b>{" "}
            to your other accounts.
          </p>
          <p>
            After you perform this action, you will be able to use your{" "}
            <b>{this.state.userInfo.federationLink}</b> account as an
            alternative authentication method to certain CERN services.
          </p>
        </div>
        <Divider />
        <Form>
          <Form.Field>
            <Button
              primary
              disabled={this.socialAccountAlreadyAssociated()}
              onClick={this.associateSocialAccount}
            >
              Confirm
            </Button>
            <Button onClick={() => this.logoutWithDelay(0)}>Cancel</Button>
          </Form.Field>
        </Form>
      </Segment>
    );

    return (
      <Container>
        {this.state.socialClient.token !== undefined &&
          this.props.cernPerson &&
          socialAccountSegment}
        <Segment color="blue">
          <Header as="h3" icon="user" content="Linked Accounts" />
          <LoadingWrapper loading={this.props.loading || loading}>
            {mappedAccounts.length === 0 && (
              <p>No accounts exist for this user</p>
            )}
            <List celled relaxed>
              {mappedAccounts.map((account) => (
                <AccountListItem
                  primary={this.isPrimaryAccount(account)}
                  onSelect={this.setPrimaryAccount}
                  disabledPrimary={this.isDisabledAssignButton(account)}
                  key={account.id}
                  account={account}
                  onUnlink={this.handleUnlink}
                />
              ))}
            </List>
          </LoadingWrapper>
        </Segment>
        <LoadingWrapper loading={this.state.socialClientLoading}>
          {this.state.socialClient.token === undefined &&
            this.props.cernPerson && (
              <Segment color="blue">
                <Header as="h3" icon="cog" content="Account Actions" />
                <Button
                  icon
                  primary
                  labelPosition="left"
                  onClick={this.loginSocialAccount}
                >
                  <Icon name="linkify" />
                  Link external account
                </Button>
                <TooltipInfoIcon
                  size="large"
                  title="Clicking the button will send you through the authentication
                    process in order to log in with an external account
                    provider, e.g. Google, Facebook, etc. Once you log in, you
                    can associate this account to your CERN identity."
                />
              </Segment>
            )}
        </LoadingWrapper>
      </Container>
    );
  }
}

Accounts.propTypes = {
  getAccounts: PropTypes.func.isRequired,
  accounts: PropTypes.array.isRequired,
  associateSocialIdentity: PropTypes.func.isRequired,
  cernPerson: PropTypes.bool.isRequired,
};

export default connect(
  (state, ownProps) => ({
    loading: isApiCallLoading(state, at.GET_CURRENT_IDENTITY_ACCOUNTS),
    accounts: selectAccounts(state),
    primaryAccount: state.accounts.primaryAccount,
    accountProviders: state.accountProviders,
    cernPerson: isCernPerson(state),
    history: ownProps.history,
  }),
  {
    getAccounts,
    setBreadcrumbs,
    associateIdentity,
    getCurrentPrimaryAccount,
    associateSocialIdentity,
    setPrimaryAccount,
    deleteAccountFromIdentity,
  }
)(Accounts);
