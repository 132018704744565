import React from "react";
import { List } from "semantic-ui-react";
import { IdentityTypes } from "./constants";

const formatAccordingToType = (fields, item, field) => {
  if (item[field] == null) {
    return "--";
  }
  if (fields[field].isDate) {
    return formatDate(item[field]);
  } else if (fields[field].isBool) {
    return item[field] ? "yes" : "no";
  }
  return item[field];
};

const formatDate = (date) => {
  return new Date(date).toLocaleString();
};

/**
 * Takes a list of fields and an object, parses it nicely into a semantic UI list
 * @param {Array[any]} fields
 * @param {Object} item
 */
export const parseItemToList = (fields, item) => {
  return (
    <List divided relaxed>
      {Object.keys(fields).map((field) => {
        const formattedField = formatAccordingToType(fields, item, field);
        if (formattedField !== undefined) {
          return listItem(fields[field].title, formattedField);
        } else {
          return <></>;
        }
      })}
    </List>
  );
};

/**
 * Renders a list item using the title and contents passed.
 * @param {string} title
 * @param {Object} contents
 */
export const listItem = (title, contents) => (
  <List.Item key={title}>
    <List.Content>
      <List.Header>{title}</List.Header>
      <List.Description>{contents}</List.Description>
    </List.Content>
  </List.Item>
);

/**
 * Verifies if an identity is a secondary or service account.
 * @param {object} identity the identity to verify
 */
export const isSecondaryOrService = (identity) =>
  identity.type === IdentityTypes.Service ||
  identity.type === IdentityTypes.Secondary;

/**
 * Verifies if an identity is a secondary
 * @param {object} identity the identity to verify
 */
export const isSecondary = (identity) =>
  identity.type === IdentityTypes.Secondary;

/**
 * Verifies if an identity is a service
 * @param {object} identity the identity to verify
 */
export const isService = (identity) => identity.type === IdentityTypes.Service;
