import React, { useState, useCallback, useEffect } from "react";
import { DatePicker } from "antd";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { Controller, useForm } from "react-hook-form";
import {
  Button,
  Container,
  Form,
  Header,
  HeaderSubheader,
  Icon,
  List,
  Message,
  Segment,
} from "semantic-ui-react";

import { validatePassword } from "../accountManagement/passwordManagement/PasswordValidTick";
import client from "./SelfServiceApiClient";
import styles from "./SelfServiceAccountActivation.module.scss";

const SelfServiceAccountActivation = ({
  match: {
    params: { requestId, approvalToken },
  },
}) => {
  const [captcha, setCaptcha] = useState({
    loading: false,
    isAudioEnabled: false,
    id: null,
    image: null,
  });
  const {
    control,
    register,
    handleSubmit,
    setError,
    clearErrors,
    getValues,
    formState: { isSubmitted, isSubmitSuccessful, isSubmitting, errors },
  } = useForm();

  const onSubmit = useCallback(
    async (formData) => {
      const dateOfBirth = new Date(
        Date.UTC(
          formData.dateOfBirth.year(),
          formData.dateOfBirth.month(),
          formData.dateOfBirth.date()
        )
      );

      const response = await client.activateAccount({
        id: requestId,
        approvalToken,
        captcha: {
          id: captcha.id,
          answer: formData.captchaAnswer,
        },
        dateOfBirth: dateOfBirth.toISOString(),
        cernPassword: formData.cernPassword,
        edhPassword: formData.edhPassword,
      });

      if (!response.ok) {
        const errorMessage = (await response.json()).message;

        setError("root.serverError", {
          type: "custom",
          message: errorMessage,
        });
      }
    },
    [approvalToken, requestId, captcha, setError]
  );

  const reloadCaptcha = useCallback(async () => {
    setCaptcha({
      ...captcha,
      loading: true,
      isAudioEnabled: false,
    });

    try {
      const data = await (await client.getCaptcha()).json();

      setCaptcha({
        loading: false,
        isAudioEnabled: false,
        id: data.id,
        image: data.img,
      });
    } catch {
      setCaptcha({
        ...captcha,
        loading: false,
        isAudioEnabled: false,
      });
    }
  }, [captcha]);

  useEffect(() => {
    reloadCaptcha();
    // Run this effect only when the component is mounted
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Segment basic>
      <Container>
        <Segment>
          <Header as="h2">
            <Icon name="user outline" /> Welcome to CERN!
          </Header>
          {errors?.root?.serverError && (
            <Message negative>
              <Message.Content>
                {errors.root.serverError.message}{" "}
                <strong>
                  Please retry and if this problem persists, contact the CERN
                  Service Desk (phone +41 22 76 77777 or service-desk@cern.ch).
                </strong>
              </Message.Content>
            </Message>
          )}
          {isSubmitSuccessful && isSubmitted ? (
            <Message positive>
              <Message.Header>CERN account activated</Message.Header>
              <p>
                Your CERN Computing Account is now active and you can start
                using it to get access to different CERN services. You will
                receive a confirmation email at your personal email address.
              </p>
            </Message>
          ) : (
            <>
              <Message info>
                <Message.Header>Passwords must contain:</Message.Header>
                <Message.Content>
                  <List as="ul" bulleted>
                    <List.Item as="li">At least 8 characters</List.Item>
                    <List.Item as="li">
                      At least 3 among
                      <List.List as="ul">
                        <List.Item as="li">Uppercase character</List.Item>
                        <List.Item as="li">Lowercase character</List.Item>
                        <List.Item as="li">Special character</List.Item>
                        <List.Item as="li">Digits</List.Item>
                      </List.List>
                    </List.Item>
                  </List>
                </Message.Content>
              </Message>
              <Form
                loading={isSubmitting || captcha.loading || captcha.id === null}
                onSubmit={(event) => {
                  // Make sure the root.serverError is cleared before the next form
                  // submission
                  clearErrors();
                  return handleSubmit(onSubmit)(event);
                }}
              >
                <Header as="h3">
                  Password for your CERN account
                  <HeaderSubheader>
                    Please provide a password for your CERN account
                  </HeaderSubheader>
                </Header>
                <Form.Field width={5}>
                  <input
                    id="cernPassword"
                    name="cernPassword"
                    placeholder="Provide your new CERN password"
                    aria-label="Provide your new CERN password"
                    type="password"
                    ref={register({
                      required: true,
                      validate: {
                        validatePassword: (cernPassword) => {
                          if (!validatePassword(cernPassword)) {
                            return "Your password does not comply with the password policy!";
                          }
                        },
                      },
                    })}
                  />
                  {errors.cernPassword && (
                    <span className={styles.fieldErrorMessage}>
                      {errors.cernPassword.message || "This field is required!"}
                    </span>
                  )}
                </Form.Field>
                <Form.Field width={5}>
                  <input
                    id="confirmCernPassword"
                    name="confirmCernPassword"
                    placeholder="Confirm your new CERN password"
                    aria-label="Confirm your new CERN password"
                    type="password"
                    ref={register({
                      required: true,
                      validate: {
                        validateConfirmationPasswordMatches: (
                          confirmCernPasswordValue
                        ) => {
                          if (
                            confirmCernPasswordValue !==
                            getValues().cernPassword
                          ) {
                            return "Password confirmation does not match!";
                          }
                        },
                      },
                    })}
                  />
                  {errors.confirmCernPassword && (
                    <span className={styles.fieldErrorMessage}>
                      {errors.confirmCernPassword?.message ||
                        "This field is required!"}
                    </span>
                  )}
                </Form.Field>
                <Header as="h3">
                  Password for Electronic Document Handling system (EDH)
                  <HeaderSubheader>
                    EDH is a CERN application to sign digital documents, and it
                    uses a separate authorization password. <br />
                    <strong>
                      Note that the EDH password must be different from your
                      CERN password.
                    </strong>
                  </HeaderSubheader>
                </Header>
                <Form.Field width={5}>
                  <input
                    id="edhPassword"
                    name="edhPassword"
                    placeholder="Provide your new EDH password"
                    aria-label="Provide your new EDH password"
                    type="password"
                    ref={register({
                      required: true,
                      validate: {
                        validateCernAndEdhPassword: (edhPasswordValue) => {
                          if (edhPasswordValue === getValues().cernPassword) {
                            return "Primary password and EDH password have to be different!";
                          }
                        },
                        validatePassword: (cernPassword) => {
                          if (!validatePassword(cernPassword)) {
                            return "Your password does not comply with the password policy!";
                          }
                        },
                      },
                    })}
                  />
                  {errors.edhPassword && (
                    <span className={styles.fieldErrorMessage}>
                      {errors.edhPassword?.message || "This field is required!"}
                    </span>
                  )}
                </Form.Field>
                <Form.Field width={5}>
                  <input
                    id="confirmEdhPassword"
                    name="confirmEdhPassword"
                    placeholder="Confirm your new EDH password"
                    aria-label="Confirm your new EDH password"
                    type="password"
                    ref={register({
                      required: true,
                      validate: {
                        validateConfirmationPasswordMatches: (
                          confirmEdhPasswordValue
                        ) => {
                          if (
                            confirmEdhPasswordValue !== getValues().edhPassword
                          ) {
                            return "Password confirmation does not match!";
                          }
                        },
                      },
                    })}
                  />
                  {errors.confirmEdhPassword && (
                    <span className={styles.fieldErrorMessage}>
                      {errors.confirmEdhPassword?.message ||
                        "This field is required!"}
                    </span>
                  )}
                </Form.Field>
                <Header as="h3">Making sure it is you</Header>
                <Form.Field>
                  <label htmlFor="dateOfBirth">
                    Date of birth (dd-mm-yyyy):
                  </label>
                  <Controller
                    id="dateOfBirth"
                    as={DatePicker}
                    aria-label="Enter your Date of birth with format day,dash, month,dash year"
                    format={["DD-MM-YYYY", "D-M-YYYY"]}
                    name="dateOfBirth"
                    control={control}
                    rules={{ required: true }}
                    defaultValue={null}
                  />
                  {errors.dateOfBirth && (
                    <p className={styles.fieldErrorMessage}>
                      This field is required
                    </p>
                  )}
                </Form.Field>
                <Form.Field>
                  <label>Captcha:</label>
                  <h4>
                    The code is valid for 60 seconds. You can reset the timer by
                    generating a new code with the <b>Reset button</b> below.
                  </h4>
                  <img
                    alt="captcha"
                    name="captchaResponseImg"
                    className={styles.captchaImage}
                    src={captcha.image}
                  />
                  <Button
                    type="button"
                    onClick={async () => await reloadCaptcha()}
                    labelPosition="right"
                    icon
                  >
                    Reset
                    <Icon name="redo" />
                  </Button>
                  <Button
                    type="button"
                    onClick={() =>
                      setCaptcha({
                        ...captcha,
                        isAudioEnabled: !captcha.isAudioEnabled,
                      })
                    }
                    icon
                  >
                    {captcha.isAudioEnabled ? "Hide " : "Show "} audio
                  </Button>
                  {captcha.isAudioEnabled && (
                    <div>
                      <audio controls="controls" className="audio-element">
                        <source src={client.getCaptchaAudioUrl(captcha.id)} />
                      </audio>
                    </div>
                  )}
                </Form.Field>
                <Form.Field>
                  <input
                    id="captchaAnswer"
                    placeholder="Enter your captcha code"
                    aria-label="Enter your captcha code"
                    name="captchaAnswer"
                    type="text"
                    ref={register({ required: true })}
                  />
                  {errors.captchaAnswer && (
                    <span className={styles.fieldErrorMessage}>
                      This field is required
                    </span>
                  )}
                </Form.Field>
                <Form.Field>
                  <Button type="submit" disabled={isSubmitting} primary>
                    Activate account
                  </Button>
                </Form.Field>
              </Form>
            </>
          )}
        </Segment>
      </Container>
    </Segment>
  );
};

SelfServiceAccountActivation.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      requestId: PropTypes.string.isRequired,
      approvalToken: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default withRouter(SelfServiceAccountActivation);
