import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Form,
  Button,
  Icon,
  Segment,
  Checkbox,
  Header,
  List,
  Grid,
} from "semantic-ui-react";
import PasswordValidTick, { validatePassword } from "./PasswordValidTick";
import PasswordMatchTick from "./PasswordMatchTick";
import { useIsDesktop } from "../../../hooks";
import { Modal } from "antd";

const ChangePasswordModal = ({ identity, onSubmit, title, loading }) => {
  const [open, setOpen] = useState(false);
  const [newPasswordError, setNewPasswordError] = useState(null);
  const [oldPasswordError, setOldPasswordError] = useState(null);

  const isDesktop = useIsDesktop();

  const close = () => {
    setNewPassword("");
    setConfirmNewPassword("");
    setCurrentPassword("");
    setConfirmRules(false);
    setNewPasswordError(null);
    setOldPasswordError(null);
    setOpen(false);
  };

  const isValidPassword = () => {
    return (
      newPassword !== confirmNewPassword ||
      !newPassword ||
      !confirmRules ||
      !confirmNewPassword ||
      !currentPassword ||
      loading ||
      !validatePassword(newPassword)
    );
  };

  const submit = async () => {
    var validPassword = isValidPassword();
    if (!validPassword) {
      const resp = await onSubmit(currentPassword, newPassword);
      if (passwordSubmissionErrors(resp)) {
        close();
      }
    } else {
      setNewPasswordError("Please fill all fields and confirm rules");
    }
  };

  const passwordSubmissionErrors = (value) => {
    if (value.payload.data && value.payload.data.error) {
      const err = value.payload.data.error;
      if (err.indexOf("old password") !== -1) {
        setOldPasswordError(err);
      }
      if (err.indexOf("new password") !== -1) {
        setNewPasswordError(err);
      }
      return false;
    }
    if (value.payload.data && value.payload.data.errors) {
      const errs = value.payload.data.errors;
      if (errs.compromised_password) {
        setNewPasswordError(errs.compromised_password);
      }
      if (errs.ActiveDirectoryManager) {
        if (errs.ActiveDirectoryManager.indexOf("new password") !== -1) {
          setNewPasswordError(errs.ActiveDirectoryManager);
        } else if (errs.ActiveDirectoryManager.indexOf("old password") !== -1) {
          setOldPasswordError(errs.ActiveDirectoryManager);
        }
      }
      if (errs.display_name_found) {
        setNewPasswordError(errs.display_name_found);
      }
      if (errs.username_found) {
        setNewPasswordError(errs.username_found);
      }
      return false;
    } else {
      return true;
    }
  };

  const [activePolicy, setActivePolicy] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [confirmRules, setConfirmRules] = useState(false);
  const handleChange = (e, { name, value }) => {
    setNewPasswordError(null);
    setOldPasswordError(null);
    if (name === "newPassword") {
      setNewPassword(value);
      setActivePolicy("8-chars");
      // DISABLED
      // if (value.length >= 20) {
      //   setActivePolicy("20-chars");
      // } else {
      //   setActivePolicy("8-chars");
      // }
    } else if (name === "confirmNewPassword") {
      setConfirmNewPassword(value);
    } else if (name === "currentPassword") {
      setCurrentPassword(value);
    }
  };

  const RestrictionsMessage = () => (
    <List className="password-policy-margin-top">
      <Header as="h4">Password requirements:</Header>
      <List.Item>
        {activePolicy === "8-chars" ? (
          <Icon className="active-policy" name="chevron circle right" />
        ) : (
          <Icon name="chevron circle right" />
        )}
        <List.Content>
          <List.Description>
            At least 8 characters long, with character restrictions.
          </List.Description>
        </List.Content>
      </List.Item>
      {/* DISABLED
                    <List.Item>
                      {activePolicy === "20-chars" ? (
                        <Icon
                          className="active-policy"
                          name="chevron circle right"
                        />
                      ) : (
                        <Icon name="chevron circle right" />
                      )}
                      <List.Content>
                        <List.Description>
                          At least 20 characters long, with no restrictions.
                        </List.Description>
                      </List.Content>
                    </List.Item> */}
    </List>
  );

  return (
    <>
      <Button
        labelPosition="left"
        fluid
        primary
        icon
        onClick={() => setOpen(true)}
      >
        <Icon name="sign-in" /> {title}
      </Button>
      <Modal
        centered
        visible={open}
        title={
          <>
            <Icon name="redo" loading={loading} />{" "}
            <h3 style={{ display: "inline-block" }}>
              {title} for account: <b>{identity.upn}</b>
            </h3>
          </>
        }
        width={700}
        onCancel={close}
        maskClosable={true}
        onOk={submit}
        okText="Confirm"
        footer={
          <>
            <Button
              onClick={close}
              negative
              icon="cancel"
              content="Cancel"
              labelPosition="right"
            />
            <Button
              onClick={submit}
              loading={loading}
              positive
              disabled={isValidPassword()}
              icon="checkmark"
              content="Confirm"
              labelPosition="right"
              htmlType="submit"
              form="change-password"
            />
          </>
        }
      >
        <Segment basic>
          <Form id="change-password">
            <Grid columns="equal" divided>
              <Grid.Row>
                <Grid.Column>
                  <Form.Group>
                    <Form.Input
                      label="Current password"
                      type="password"
                      name="currentPassword"
                      id="changeCurrentPasswordField"
                      aria-label="Enter current password"
                      value={currentPassword}
                      error={oldPasswordError}
                      onChange={handleChange}
                      autoFocus={true}
                    />
                  </Form.Group>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Form.Group>
                    <Form.Input
                      label="New password"
                      type="password"
                      name="newPassword"
                      id="changeNewPasswordField"
                      aria-label="Enter new password an uppercase,lowercase,special character and digit "
                      value={newPassword}
                      onChange={handleChange}
                      error={newPasswordError}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Input
                      label="Confirm new password"
                      type="password"
                      name="confirmNewPassword"
                      aria-label="Enter new password again to confirm"
                      id="changeConfirmNewPasswordField"
                      value={confirmNewPassword}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  {!isDesktop && <RestrictionsMessage />}
                </Grid.Column>
                {isDesktop && (
                  <Grid.Column className="policies">
                    <RestrictionsMessage />
                  </Grid.Column>
                )}
              </Grid.Row>
            </Grid>
            <PasswordValidTick
              newPassword={newPassword}
              setNewPasswordError={setNewPasswordError}
            />
            <PasswordMatchTick
              newPassword={newPassword}
              confirmNewPassword={confirmNewPassword}
            />
            <Form.Group>
              <Form.Field className="password-oc5-margin">
                <Checkbox
                  id="checkbox"
                  checked={confirmRules}
                  onChange={() => setConfirmRules(!confirmRules)}
                  label={
                    <label htmlFor="checkbox">
                      I confirm that I have read, understood and will abide by
                      the{" "}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://security.web.cern.ch/rules/en/index.shtml"
                      >
                        CERN Computing Rules (OC5){" "}
                      </a>
                      *
                    </label>
                  }
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </Segment>
      </Modal>
    </>
  );
};

ChangePasswordModal.propTypes = {
  loading: PropTypes.bool.isRequired,
  identity: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default ChangePasswordModal;
