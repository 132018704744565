import { keycloakReducer as keycloak } from "@authzsvc/keycloak-js-react";
import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import accountProviders from "./accountProviders";
import accounts from "./accounts";
import breadcrumbs from "./breadcrumbs";
import extraNotifications from "./extraNotifications";
import groups from "./groups";
import identity from "./identity";
import loading from "./loadingSpinner";
import requests from "./requests";
import selfServiceReset from "./selfServiceReset";
import mfaCredentials from "./mfaCredentials";

export default (history) =>
  combineReducers({
    keycloak,
    selfServiceReset,
    extraNotifications,
    accountProviders,
    identity,
    loading,
    groups,
    breadcrumbs,
    accounts,
    requests,
    mfaCredentials,
    router: connectRouter(history),
  });
