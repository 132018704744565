import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSSPRError } from "../../selectors";
import { Message } from "semantic-ui-react";
import { clearSSPRError } from "../../actions/hookActions";

const SSPRError = () => {
  const dispatch = useDispatch();
  const error = useSelector(getSSPRError);

  const clearError = () => {
    dispatch(clearSSPRError());
  };

  return (
    <>
      {error && error.length > 0 && (
        <Message negative onDismiss={() => clearError()}>
          {error}
        </Message>
      )}
    </>
  );
};

export default SSPRError;
