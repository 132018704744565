import { errorAction, types as at } from "@authzsvc/api-lib";
import {
  PhonebookLink,
  LoadingWrapper,
  ComputingRules,
  Affiliation,
  DeadlineDisplay,
  ExternalEmail,
} from "@authzsvc/common-react-components";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  Container,
  Grid,
  Header,
  Icon,
  Popup,
  Segment,
  Table,
} from "semantic-ui-react";
import {
  changePassword,
  changeEdhPassword,
  getCurrentPrimaryAccount,
  getIdentity,
  getMailProperties,
  updateExternalEmail,
} from "../../actions/api";
import { setBreadcrumbs } from "../../actions/breadcrumbs";
import "../../assets/Identity.css";
import {
  isApiCallLoading,
  isCernAccount,
  isCernPerson,
  isPrimaryAccountCern,
  selectAccountManagementOn,
  selectIdentity,
} from "../../selectors";
import Accounts from "../accounts/Accounts";
import Orcid from "./Orcid";
import ResolvedIdentity from "../common/ResolvedIdentity";
import MailAlias from "../accountManagement/mailSettings/MailAlias";
import UseExternalMailBox from "../accountManagement/mailSettings/UseExternalMailBox";
import { notification } from "antd";
import GidInfo from "../common/GidInfo";
import GidAssignmentModal from "../common/GidAssignmentModal";
import { withApiCapabilities } from "../../wrappers";
import { phonebookUrl } from "../../config";
import { ScreenSize } from "../../constants";
import IdentityEditActions from "./IdentityEditActions";
import OtherAccountsManagement from "../accountManagement/OtherAccountsManagement";
import { Tabs } from "antd";
const { TabPane } = Tabs;

class Identity extends Component {
  state = {
    mail: {},
    initialMail: {},
    aliasError: null,
    aliasConfirm: false,
    externalEmailSwitchAvailability: false,
    isDesktop: window.innerWidth > ScreenSize.Mobile,
    activeIndex: 0,
  };

  updateMedia = () => {
    this.setState({ isDesktop: window.innerWidth > ScreenSize.Mobile });
  };

  async componentDidMount() {
    this.updateMedia();
    this.props.setBreadcrumbs(this.props.breadcrumbs);
    this.props.getIdentity();
    this.props.getCurrentPrimaryAccount();
    window.addEventListener("resize", this.updateMedia);
    if (this.props.active_tab) {
      this.setState({
        activeIndex: this.props.active_tab,
      });
    }
  }

  async componentWillUnmount() {
    window.removeEventListener("resize", this.updateMedia);
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.identity !== this.props.identity) {
      const mail = await this.props.getMailProperties(this.props.identity.id);
      this.setState({
        mail: mail.payload.data,
      });
      this.setState({
        initialMail: mail.payload.data,
      });
    }
  }

  confirmationInfo = () => {
    notification["info"]({
      message: "Tip!",
      description:
        'Click "Confirm Updates" to register your changes regarding Aliases!',
    });
  };

  setMail = (mail) => {
    this.setState({
      mail: mail,
    });
  };

  goToOrcid() {
    window.open("https://orcid.org", "_blank");
  }

  goToPasswordManagement() {
    this.props.history.push(`/accounts`);
  }

  goToAccounts() {
    this.props.history.push(`/accounts`);
  }

  handleTabChange = (e, { activeIndex }) => this.setState({ activeIndex });

  submitExternalEmail = async (value) => {
    const resp = await this.props.updateExternalEmail(
      this.props.identity.id,
      value
    );
    if (resp.type === errorAction(at.UPDATE_EXTERNAL_EMAIL)) {
      notification["error"]({
        message: resp.payload.title,
        description: resp.payload.data.message,
      });
    }
    return resp;
  };

  fetchMailProperties = async () => {
    return await this.props.getMailProperties(this.props.identity.id);
  };

  ACCOUNT_OVERVIEW_PANE = () => (
    <>
      <LoadingWrapper loading={this.props.loading}>
        <Segment color="blue">
          <Grid columns={this.state.isDesktop ? 2 : 1}>
            <Grid.Column width={this.state.isDesktop ? 12 : 16}>
              <Segment basic>
                <Header as="h3">
                  <Icon name="info" />
                  <Header.Content>
                    {this.props.identity.displayName}{" "}
                    <PhonebookLink
                      portalUrl={phonebookUrl}
                      email={this.props.identity.primaryAccountEmail}
                    />
                  </Header.Content>
                </Header>
                <Table celled striped definition>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell colSpan="2" className="table-header">
                        Details
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>Username</Table.Cell>
                      <Table.Cell>{this.props.identity.upn}</Table.Cell>
                    </Table.Row>
                    {this.props.identity.type === "Person" && (
                      <Table.Row>
                        <Table.Cell>Affiliation</Table.Cell>
                        <Table.Cell>
                          <Affiliation
                            activeUser={this.props.identity.activeUser}
                            affiliationInfo={this.props.identity}
                          />
                        </Table.Cell>
                      </Table.Row>
                    )}
                    <Table.Row>
                      <Table.Cell>Email</Table.Cell>
                      <Table.Cell>
                        {this.props.identity.primaryAccountEmail}
                        {!this.props.identity.primaryAccountEmail && "--"}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>
                        ORCID iD{" "}
                        <Popup
                          floated="right"
                          content="ORCID provides a persistent identifier that distinguishes you from other researchers and a mechanism for linking your research outputs and activities to your iD."
                          trigger={
                            <Icon
                              color="blue"
                              floated="right"
                              link
                              onClick={this.goToOrcid}
                              name="question"
                            />
                          }
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <Orcid identity={this.props.identity} />
                      </Table.Cell>
                    </Table.Row>
                    {this.props.identity.ownerId && (
                      <Table.Row>
                        <Table.Cell>Owner</Table.Cell>
                        <Table.Cell>
                          <ResolvedIdentity id={this.props.identity.ownerId} />
                        </Table.Cell>
                      </Table.Row>
                    )}
                    {this.props.identity.supervisorId && (
                      <Table.Row>
                        <Table.Cell>Supervisor</Table.Cell>
                        <Table.Cell>
                          <ResolvedIdentity
                            id={this.props.identity.supervisorId}
                          />
                        </Table.Cell>
                      </Table.Row>
                    )}
                    {this.props.identity.directResponsibleId && (
                      <Table.Row>
                        <Table.Cell>Direct Responsible</Table.Cell>
                        <Table.Cell>
                          <ResolvedIdentity
                            id={this.props.identity.directResponsibleId}
                          />
                        </Table.Cell>
                      </Table.Row>
                    )}
                    <Table.Row>
                      <Table.Cell>Type</Table.Cell>
                      <Table.Cell>{this.props.identity.type}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>
                        <GidInfo />
                      </Table.Cell>
                      <Table.Cell>
                        <GidAssignmentModal
                          identity={this.props.identity}
                          onSubmit={() => this.props.getIdentity()}
                        />
                      </Table.Cell>
                    </Table.Row>
                    {this.props.identity.type === "Person" && (
                      <Table.Row>
                        <Table.Cell>Computing Rules</Table.Cell>
                        <Table.Cell>
                          <ComputingRules
                            accepted={
                              this.props.identity.computingRulesAcceptedFlag
                            }
                            computingRulesInfo={this.props.identity}
                          />
                        </Table.Cell>
                      </Table.Row>
                    )}
                    <Table.Row>
                      <Table.Cell>Will expire on</Table.Cell>
                      <Table.Cell>
                        <DeadlineDisplay
                          deadline={this.props.identity.blockingDeadline}
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Will be deleted on</Table.Cell>
                      <Table.Cell>
                        <DeadlineDisplay
                          deadline={this.props.identity.expirationDeadline}
                        />
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Segment>
            </Grid.Column>
            <Grid.Column floated="right" width={this.state.isDesktop ? 4 : 16}>
              {!this.props.loading && <IdentityEditActions {...this.props} />}
            </Grid.Column>
          </Grid>
        </Segment>
      </LoadingWrapper>
    </>
  );

  LINKED_ACCOUNTS_PANE = () => <Accounts {...this.props} />;

  SECONDARY_AND_SERVICE_ACCOUNTS_PANE = () => (
    <OtherAccountsManagement {...this.props} />
  );

  MAIL_SETTINGS_PANE = () => (
    <>
      <LoadingWrapper loading={this.props.loading}>
        <Segment color="blue">
          <Segment basic>
            <Table definition celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell colSpan="2">
                    <Header as="h3">
                      <Icon name="mail" />
                      <Header.Content>
                        Mail settings for: {this.props.identity.upn}
                      </Header.Content>
                    </Header>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>
                    External email
                    <Popup
                      floated="right"
                      content="An external (non-CERN) email address that can be used to contact you"
                      trigger={
                        <Icon
                          color="blue"
                          floated="right"
                          link
                          name="question"
                        />
                      }
                    />
                  </Table.Cell>
                  <Table.Cell>
                    {this.state.mail && (
                      <ExternalEmail
                        accountManagementOn={this.props.accountManagementOn}
                        onUpdate={(value) => this.submitExternalEmail(value)}
                        onUpdateExternal={() => this.fetchMailProperties()}
                        mail={this.state.mail}
                      />
                    )}
                    <br />
                    {this.props.cernAccount &&
                      this.props.accountManagementOn &&
                      this.state.externalEmailSwitchAvailability && (
                        <UseExternalMailBox identity={this.props.identity} />
                      )}
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
            <>
              {this.props.cernAccount &&
                this.props.accountManagementOn &&
                this.state.mail && (
                  <MailAlias
                    accountManagementOn={this.props.accountManagementOn}
                    mail={this.state.mail}
                    identity={this.props.identity}
                    setMail={this.setMail}
                  />
                )}
            </>
          </Segment>
        </Segment>
      </LoadingWrapper>
    </>
  );

  render() {
    const items = [
      {
        key: "1",
        label: (
          <>
            <Header>Account Overview</Header>
          </>
        ),
        children: this.ACCOUNT_OVERVIEW_PANE(),
      },
      {
        key: "2",
        label: (
          <>
            <Header>Mail Settings</Header>
          </>
        ),
        children: this.props.cernAccount && this.MAIL_SETTINGS_PANE(),
      },
      {
        key: "3",
        label: (
          <>
            <Header>Linked Accounts</Header>
          </>
        ),
        children: this.props.cernAccount && this.LINKED_ACCOUNTS_PANE(),
      },
      {
        key: "4",
        label: (
          <>
            <Header>Secondary and Service Accounts</Header>
          </>
        ),
        children:
          this.props.cernAccount && this.SECONDARY_AND_SERVICE_ACCOUNTS_PANE(),
      },
    ];

    return (
      <Container>
        <Tabs defaultActiveKey="1" className="tabs">
          <TabPane
            tab={items[0].label}
            key={items[0].key}
            children={items[0].children}
            className="tabs-pane"
          ></TabPane>
          <TabPane
            tab={items[1].label}
            key={items[1].key}
            children={items[1].children}
            className="tabs-pane"
          ></TabPane>
          <TabPane
            tab={items[2].label}
            key={items[2].key}
            children={items[2].children}
            className="tabs-pane"
          ></TabPane>
          <TabPane
            tab={items[3].label}
            key={items[3].key}
            children={items[3].children}
            className="tabs-pane"
          ></TabPane>
        </Tabs>
      </Container>
    );
  }
}

Identity.propTypes = {
  identity: PropTypes.object.isRequired,
  breadcrumbs: PropTypes.array.isRequired,
  active_tab: PropTypes.number,
};

export default withApiCapabilities(
  withRouter(
    connect(
      (state, ownProps) => ({
        accountManagementOn: selectAccountManagementOn(ownProps.capabilities),
        identity: selectIdentity(state),
        loading:
          isApiCallLoading(state, at.GET_IDENTITY) ||
          !ownProps.loadedCapabilities,
        cernPerson: isCernPerson(state),
        primaryAccountCern: isPrimaryAccountCern(state),
        cernAccount: isCernAccount(state),
        primaryAccount: state.accounts.primaryAccount,
        history: ownProps.history,
        loadingPasswords:
          isApiCallLoading(state, at.CHANGE_PASSWORD) ||
          isApiCallLoading(state, at.CHANGE_EDHPASSWORD),
      }),
      {
        getIdentity,
        setBreadcrumbs,
        getCurrentPrimaryAccount,
        changePassword,
        getMailProperties,
        changeEdhPassword,
        updateExternalEmail,
      }
    )(Identity)
  )
);
