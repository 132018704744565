import {
  types as at,
  successAction,
  ApiError,
  errorAction,
} from "@authzsvc/api-lib";
import { notification } from "antd";

export default (state = {}, action) => {
  switch (action.type) {
    case successAction(at.RESET_PASSWORD):
      notification.success({
        message: "Password reset successful!",
        description:
          "The password of your account has been successfully reset. Please logout and login again.",
      });
      return state;
    case successAction(at.SET_INITIAL_PASSWORD):
      notification.success({
        message: "Initial password set successful!",
        description:
          "The password of your account has been successfully initialized.",
      });
      return state;
    case successAction(at.CHANGE_PASSWORD):
      notification.success({
        message: "Password change successful!",
        description:
          "The password of your account has been successfully changed. Please logout and login again.",
      });
      return state;
    case successAction(at.CHANGE_EDHPASSWORD):
      notification.success({
        message: "EDH Password change successful!",
        description:
          "Your EDH signature password has been successfully changed.",
      });
      return state;
    case errorAction(at.CHANGE_EDHPASSWORD):
      let errorMsg = "An unknown error has occured";
      if (action.payload && action.payload instanceof ApiError) {
        errorMsg = action.payload.data.error;
      }
      notification.error({
        message: "EDH password change failed",
        description: errorMsg,
      });
      return state;
    case successAction(at.CREATE_IDENTITY):
      notification.success({
        message: "Account created",
        description:
          "Your new account has been created, it may take several hours to be fully available.",
      });
      return state;
    case successAction(at.PATCH_IDENTITY):
      notification.success({
        message: "Account updated",
        description:
          "Your account has been updated with the desired information.",
      });
      return state;
    case successAction(at.UPDATE_IDENTITY):
      notification.success({
        message: "Account updated",
        description: "The account has been successfully updated.",
      });
      return state;
    case successAction(at.CREATE_SERVICE):
      notification.success({
        message: "Login created",
        description:
          "Your new login has been created, it may take several hours to be fully available.",
      });
      return state;
    case successAction(at.CREATE_SECONDARY):
      notification.success({
        message: "Login created",
        description:
          "Your new login has been created, it may take several hours to be fully available.",
      });
      return state;
    case errorAction(at.CHANGE_PASSWORD):
      notification.error({
        message: "Password change failed!",
        description:
          "An error has occurred and the password of your account has not been changed.",
      });
      return state;
    case errorAction(at.RESET_PASSWORD):
      notification.error({
        message: "Password reset failed!",
        description:
          "An error has occurred and the password of your account has not been reset.",
      });
      return state;
    case successAction(at.DELETE_IDENTITY):
      notification.success({
        message: "Login deleted",
        description: "Your login has been deleted",
      });
      return state;
    case successAction(at.TRANSFER_IDENTITY):
      let description =
        "A transfer request has been created and is in progress";
      if (action.payload.request_id) {
        description =
          "A transfer request has been created, the new owner will need to approve.";
      }
      notification.success({
        message: "A transfer request has been created",
        description: description,
      });
      return state;
    default:
      return state;
  }
};
