import PropTypes from "prop-types";
import React from "react";
import { Tooltip } from "antd";
import { connect } from "react-redux";
import { Icon, Segment, Header, Grid, Button } from "semantic-ui-react";
import { isApiCallLoading, selectAccountManagementOn } from "../../selectors";
import { withApiCapabilities } from "../../wrappers";
import ChangePasswordModal from "../accountManagement/passwordManagement/ChangePasswordModal";
import { errorAction, types as at } from "@authzsvc/api-lib";
import { isSecondaryOrService } from "../../utils";
import {
  changePassword,
  changeEdhPassword,
  resetPassword,
  setInitialPassword,
} from "../../actions/api";
import { notification } from "antd";

const IdentityEditActions = ({
  identity,
  history,
  primaryAccount,
  cernAccount,
  loadingPasswords,
  changePassword,
  changeEdhPassword,
}) => {
  const onChangePassword = async (oldPassword, newPassword) => {
    const changeResp = await changePassword(
      primaryAccount.id,
      oldPassword,
      newPassword
    );
    if (changeResp.type === errorAction(at.CHANGE_PASSWORD)) {
      notification["error"]({
        message: changeResp.payload.title,
        description: changeResp.payload.data.message,
      });
    }
    return changeResp;
  };

  const onChangeEdhPassword = async (oldPassword, newPassword) => {
    const changeEdhResp = await changeEdhPassword(
      primaryAccount.id,
      oldPassword,
      newPassword
    );
    if (changeEdhResp.type === errorAction(at.CHANGE_EDHPASSWORD)) {
      notification["error"]({
        message: changeEdhResp.payload.title,
        description: changeEdhResp.payload.data.message,
      });
    }
    return changeEdhResp;
  };

  const goToPrimaryAccount = () => {
    history.push(`/accounts/${primaryAccount.id}`);
  };

  return (
    <>
      <Segment basic>
        <Header as="h3">
          <span>
            <Icon name="setting" />
            Actions
          </span>
        </Header>
        <Grid columns={1}>
          <Grid.Row>
            <Button
              onClick={goToPrimaryAccount}
              icon
              labelPosition="left"
              primary
              fluid
            >
              <Icon name="lock" />
              Configure 2FA
            </Button>
          </Grid.Row>
          {cernAccount && (
            <>
              <Grid.Row>
                <ChangePasswordModal
                  loading={loadingPasswords}
                  onSubmit={(oldPassword, newPassword) =>
                    onChangePassword(oldPassword, newPassword)
                  }
                  identity={identity}
                  title={"Change password"}
                />
              </Grid.Row>
              {!isSecondaryOrService(identity) && (
                <Grid.Row>
                  <ChangePasswordModal
                    loading={loadingPasswords}
                    onSubmit={(oldPassword, newPassword) =>
                      onChangeEdhPassword(oldPassword, newPassword)
                    }
                    identity={identity}
                    title={"Change EDH password"}
                  />
                </Grid.Row>
              )}
              <Grid.Row>
                <Tooltip title="Create a Service or Secondary account (this will open a link to the old account website)">
                  <a
                    className="ui icon primary left fluid labeled button"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://account.cern.ch/account/Management/NewAccount.aspx"
                  >
                    <i aria-hidden="true" className="external icon" />
                    Create new account
                  </a>
                </Tooltip>
              </Grid.Row>
              <Grid.Row>
                <Tooltip title="Edit your email or add new mail aliases (this will open a link to the old account website)">
                  <a
                    className="ui icon primary fluid left labeled button"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://account.cern.ch/account/Management/MyAccounts.aspx"
                  >
                    <i aria-hidden="true" className="external icon" />
                    Edit mail settings
                  </a>
                </Tooltip>
              </Grid.Row>
            </>
          )}
        </Grid>
      </Segment>
    </>
  );
};

IdentityEditActions.propTypes = {
  identity: PropTypes.object.isRequired,
  accountManagementOn: PropTypes.bool.isRequired,
};

export default withApiCapabilities(
  connect(
    (state, ownProps) => ({
      accountManagementOn: selectAccountManagementOn(ownProps.capabilities),
      loadingPasswords:
        isApiCallLoading(state, at.CHANGE_PASSWORD) ||
        isApiCallLoading(state, at.CHANGE_EDHPASSWORD),
    }),
    {
      changePassword,
      changeEdhPassword,
      resetPassword,
      setInitialPassword,
    }
  )(IdentityEditActions)
);
