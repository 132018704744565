import React, { useState } from "react";
import PropTypes from "prop-types";
import { updateMailProperties } from "../../../actions/api";
import { errorAction, successAction, types as at } from "@authzsvc/api-lib";
import { notification } from "antd";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { withApiCapabilities } from "../../../wrappers";
import _ from "lodash";
import { SetForwardModal } from "@authzsvc/common-react-components";

const SetForward = ({ mail, setMail, identity, updateMailProperties }) => {
  const [forward, setForward] = useState(false);
  const [forwardEmail, setForwardEmail] = useState({});

  const submit = async () => {
    if (_.isNil(forwardEmail)) {
      notification["failure"]({
        message: "Sorry, something went wrong",
      });
    } else {
      console.log("Updating forward ", forwardEmail);
      const emailResult = await updateMailProperties(identity.id, {
        ...mail,
        forwardingAddress: forwardEmail,
        forwardOnly: forward,
      });
      if (emailResult.type === successAction(at.UPDATE_MAIL_PROPERTIES)) {
        notification["success"]({
          message: "Forward updated successfully",
        });
        setMail(emailResult.payload.data);
      } else if (emailResult.type === errorAction(at.UPDATE_MAIL_PROPERTIES)) {
        notification["error"]({
          message: emailResult.payload.title,
          description: emailResult.payload.data.message,
        });
      }
    }
  };

  return (
    <SetForwardModal
      forward={forward}
      setForward={setForward}
      setForwardEmail={setForwardEmail}
      onSetForwardSubmit={() => submit()}
    />
  );
};

SetForward.propTypes = {
  mail: PropTypes.object.isRequired,
  setMail: PropTypes.func.isRequired,
  identity: PropTypes.object.isRequired,
};

export default withApiCapabilities(
  withRouter(
    connect((state, ownProps) => ({}), {
      updateMailProperties,
    })(SetForward)
  )
);
