import React from "react";
import PropTypes from "prop-types";
import { updateIdentity } from "../../actions/api";
import { types as at } from "@authzsvc/api-lib";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { withApiCapabilities } from "../../wrappers";
import EditObjectPropertyModal from "../common/EditObjectPropertyModal";

const EditIdentityPropertyModal = ({
  identity,
  setIdentity,
  field,
  title,
  onValidate,
  readOnly,
  // redux actions
  updateIdentity,
}) => {
  return (
    <EditObjectPropertyModal
      setObject={setIdentity}
      object={identity}
      field={field}
      title={title}
      onValidate={onValidate}
      updateObject={updateIdentity}
      apiCallType={at.UPDATE_IDENTITY}
      readOnly={readOnly}
    />
  );
};

EditIdentityPropertyModal.propTypes = {
  setIdentity: PropTypes.func.isRequired,
  identity: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onValidate: PropTypes.func.isRequired,
};

export default withApiCapabilities(
  withRouter(
    connect((state, ownProps) => ({}), {
      updateIdentity,
    })(EditIdentityPropertyModal)
  )
);
