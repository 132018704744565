import { types as at, successAction } from "@authzsvc/api-lib";

const initialState = {
  my: {},
  search: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case successAction(at.GET_IDENTITY):
      return { ...state, my: action.payload.data };
    case successAction(at.SEARCH_IDENTITY):
      return { ...state, search: action.payload };
    case successAction(at.PATCH_IDENTITY):
      // The primaryAccountEmail is not returned in the Patch response so keep it
      action.payload.data.primaryAccountEmail = state.my.primaryAccountEmail;
      return { ...state, my: action.payload.data };
    default:
      return state;
  }
};
