import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import App from "./App";
import GuestAccountRegistration from "./components/accounts/GuestAccountRegistration";
import CompleteSelfServicePasswordReset from "./components/selfservice/CompleteSelfServicePasswordReset";
import SelfServicePasswordReset from "./components/selfservice/SelfServicePasswordReset";
import SelfServiceAccountActivation from "./components/selfservice/SelfServiceAccountActivation";
import styles from "./assets/Switch.module.css";

const AppWrapper = () => {
  return (
    <div>
      <div className={styles.switchChildren}>
        <Switch>
          <Route
            path="/self-service-reset/complete/:code"
            component={CompleteSelfServicePasswordReset}
          />
          <Route
            path="/self-service-reset"
            component={SelfServicePasswordReset}
          />
          <Route
            path="/guest-registration"
            component={GuestAccountRegistration}
          />
          <Route
            path="/self-service-activation/:requestId/:approvalToken"
            component={SelfServiceAccountActivation}
          />
          <Route path="/" component={App} />
        </Switch>
      </div>
    </div>
  );
};

export default withRouter(AppWrapper);
