import React from "react";
import { Icon, Popup } from "semantic-ui-react";

const GidInfo = () => {
  return (
    <>
      Computing Group{" "}
      <Popup
        floated="right"
        content="The Unix GID associated to your account, you can select one from any computing group that you belong to."
        trigger={<Icon color="blue" floated="right" link name="question" />}
      />
    </>
  );
};

export default GidInfo;
