export const SSPR_ERROR = "Self service password reset error";
export const CLEAR_SSPR_ERROR = "Clear self service password reset error";

export function registerSSPRError(err) {
  return { type: SSPR_ERROR, err: err };
}

export function clearSSPRError() {
  return { type: CLEAR_SSPR_ERROR };
}
