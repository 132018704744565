import React from "react";
import { Dropdown } from "semantic-ui-react";
import { Link } from "react-router-dom";
import * as cfg from "../../config";
import RequestsHeaderButton from "./RequestHeaderButton";

const HeaderDropdownItems = () => (
  <>
    <Dropdown.Divider />
    <Dropdown.Item text="My Account" as={Link} to="/" />
    <br />
    <Dropdown.Item text="Groups" as={Link} to="/groups" />
    <br />
    <Dropdown.Item text="Other Accounts" as={Link} to="/accounts" />
    <br />
    <RequestsHeaderButton />
    <br />
    <Dropdown.Divider />
    <Dropdown.Item text="Help" icon="help" href={cfg.sidebar_help} />
    <br />
  </>
);

export default HeaderDropdownItems;
