// Keycloak configs
export const keycloakUrl = process.env.REACT_APP_KEYCLOAK_URL;
export const keycloakRealm = process.env.REACT_APP_KEYCLOAK_REALM;
export const keycloakClientId = process.env.REACT_APP_KEYCLOAK_CLIENT_ID;
export const keycloakSocialRealm = process.env.REACT_APP_KEYCLOAK_SOCIAL_REALM;

// Social realm username claim (from the token)
export const keycloakSocialRealmUsernameClaim =
  process.env.REACT_APP_KEYCLOAK_SOCIAL_USERNAME_CLAIM;

// Which Role should be used to see whether users are authenticted with 2nd factor
export const mfaRoleIdentifier = process.env.REACT_APP_MFA_ROLE;
export const authzsvcApiID = process.env.REACT_APP_AUTHZSVC_APPLICATION_ID;
export const mfaCriticalRoleIdentifier =
  process.env.REACT_APP_MFA_CRITICAL_ROLE;
export const mfaRedirectUrl = process.env.REACT_APP_KEYCLOAK_REDIRECT_MFA;

export const refreshKeycloakToken =
  process.env.REACT_APP_KEYCLOAK_REFRESH_TOKEN === "true";

// Auth service client params
export const baseAuthorizationServerUrl =
  process.env.REACT_APP_AUTH_SERVICE_URL;
export const tokenExchangeEndpoint =
  process.env.REACT_APP_TOKEN_EXCHANGE_ENDPOINT;
export const authAudience = process.env.REACT_APP_AUTH_AUDIENCE;
export const userAudience = process.env.REACT_APP_USER_AUDIENCE;
export const grantType = process.env.REACT_APP_GRANT_TYPE;
export const subjectTokenType = process.env.REACT_APP_SUBJECT_TOKEN_TYPE;

// Sidebar links
export const sidebar_resources = process.env.REACT_APP_SIDEBAR_RESOURCES;
export const sidebar_help = process.env.REACT_APP_SIDEBAR_HELP;
export const sidebar_applications = process.env.REACT_APP_SIDEBAR_APPLICATIONS;
export const sidebar_identities = process.env.REACT_APP_SIDEBAR_IDENTITIES;
export const sidebar_groups = process.env.REACT_APP_SIDEBAR_GROUPS;

// Account Management
export const activeDirectoryScope = "ActiveDirectory";

// Self Service API endpoint
export const selfServiceApiUrl = process.env.REACT_APP_SELF_SERVICE_API_URL;

// Phonebook URL
export const phonebookUrl = process.env.REACT_APP_PHONEBOOK_URL;

// ORCID Client ID
export const orcidClientId = process.env.REACT_APP_ORCID_CLIENT_ID;

console.log("Setting environment as:");
console.log(process.env);
