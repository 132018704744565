import { successAction, types } from "@authzsvc/api-lib";

const initialState = {
  otp: {
    enabled: false,
    initialization_required: true,
    preferred: false,
    credential_id: undefined,
  },
  webauthn: {
    enabled: false,
    initialization_required: true,
    preferred: false,
    credential_id: undefined,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case successAction(types.GET_MFA_SETTINGS_FOR_ACCOUNT):
      return {
        ...action.payload,
      };
    case successAction(types.SET_MFA_SETTINGS_FOR_ACCOUNT):
      return {
        ...action.payload,
      };
    default:
      return state;
  }
};
