import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  Segment,
  Label,
  Container,
  Message,
  Icon,
  List,
} from "semantic-ui-react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { selectGroupById, isApiCallLoading } from "../../selectors";
import { types as at } from "@authzsvc/api-lib";
import { getGroupById } from "../../actions/api";
import { LoadingWrapper } from "@authzsvc/common-react-components";
import { setBreadcrumbs } from "../../actions/breadcrumbs";
import { parseItemToList, listItem } from "../../utils";
import ResolvedIdentity from "../common/ResolvedIdentity";

const fields = {
  displayName: { title: "Name" },
  description: { title: "Description" },
  public: { title: "Public", isBool: true },
  resourceCategory: { title: "Resource category" },
  blocked: { title: "Blocked", isBool: true },
  blockingReason: { title: "Blocking reason" },
  blockingTime: { title: "Blocking time" },
};

const GroupDetails = ({
  // redux props
  group,
  breadcrumbs,
  loading,
  match,
  // redux actions
  getGroupById,
  setBreadcrumbs,
}) => {
  useEffect(() => {
    if (!group) {
      getGroupById(match.params.id);
    }
    setBreadcrumbs([
      ["/groups", "Groups"],
      ["/groups/details", group && group.groupIdentifier],
    ]);
  }, [group, match, breadcrumbs, getGroupById, setBreadcrumbs]);

  return (
    <Container>
      <LoadingWrapper loading={loading}>
        {group !== undefined ? (
          <Segment raised>
            <Label color="blue" ribbon>
              Identifier: {group.groupIdentifier}
            </Label>
            <List divided relaxed>
              {listItem(
                "Owner",
                group.ownerId ? <ResolvedIdentity id={group.ownerId} /> : "--"
              )}
            </List>
            {parseItemToList(fields, group)}
          </Segment>
        ) : (
          <Message icon>
            <Icon name="exclamation circle" />
            <Message.Content>No group found for this ID</Message.Content>
          </Message>
        )}
      </LoadingWrapper>
    </Container>
  );
};

GroupDetails.propTypes = {
  // fcts
  setBreadcrumbs: PropTypes.func.isRequired,
};

// TODO add connected react router for getting the match params inside here
export default withRouter(
  connect(
    (state, router) => ({
      group: selectGroupById(state, router.match.params.id),
      loading: isApiCallLoading(state, at.GET_GROUP_BY_ID),
    }),
    { getGroupById, setBreadcrumbs }
  )(GroupDetails)
);
