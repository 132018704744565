import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Input, Segment } from "semantic-ui-react";
import { successAction } from "@authzsvc/api-lib";
import { notification } from "antd";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { withApiCapabilities } from "../../wrappers";
import { ErrorLabel } from "@authzsvc/common-react-components";

const EditObjectPropertyModal = ({
  object,
  setObject,
  field,
  title,
  onValidate,
  updateObject,
  apiCallType,
  readOnly,
}) => {
  const [open, setOpen] = useState(false);
  const [newValue, setNewValue] = useState("");
  const [propertyError, setPropertyError] = useState("");
  const [disabledConfirm, setDisabledConfirm] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const close = () => {
    setOpen(false);
  };

  const submit = async () => {
    console.log(`Setting ${field}`);
    setSubmitting(true);
    let data = { ...object };
    data[field] = newValue;
    let newObject = await updateObject(data);
    if (newObject.type === successAction(apiCallType)) {
      // There is already a global success alert for object updates
      setObject(newObject.payload.data);
      close();
    } else {
      notification["failure"]({
        message: "Sorry, something went wrong",
      });
    }
    setSubmitting(false);
  };

  const handleChange = (fieldName) => (event) => {
    const value = event.target.value;
    let check = onValidate(value);
    setPropertyError(check);
    setDisabledConfirm(check);
    setNewValue(value);
  };

  return (
    <>
      {/* If it's read only, don't display the modal button since we can't edit */}
      {readOnly ? (
        <></>
      ) : (
        <Modal
          size="tiny"
          closeOnDimmerClick={true}
          onClose={close}
          trigger={
            <Button
              floated="right"
              icon="edit"
              color={"yellow"}
              onClick={() => setOpen(true)}
            />
          }
          open={open}
          header={`Set ${title}`}
          content={
            <Segment basic>
              Enter the new value:{" "}
              <Input
                error={propertyError.length > 0}
                readOnly={readOnly}
                focus
                onChange={handleChange()}
                defaultValue={object[field]}
              />
              <br />
              {propertyError && (
                <ErrorLabel pointing="above">{propertyError}</ErrorLabel>
              )}
            </Segment>
          }
          actions={[
            {
              key: "cancel",
              content: "Cancel",
              color: "red",
              icon: "cancel",
              positive: false,
              onClick: close,
              disabled: submitting,
            },
            {
              disabled: disabledConfirm,
              key: "done",
              icon: "checkmark",
              content: "Confirm",
              positive: true,
              onClick: submit,
              loading: submitting,
            },
          ]}
        />
      )}
    </>
  );
};

EditObjectPropertyModal.propTypes = {
  setObject: PropTypes.func.isRequired,
  object: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onValidate: PropTypes.func.isRequired,
  updateObject: PropTypes.func.isRequired,
  apiCallType: PropTypes.string.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

export default withApiCapabilities(
  withRouter(connect((state, ownProps) => ({}), {})(EditObjectPropertyModal))
);
