import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Segment, Button, Container, Form, Loader } from "semantic-ui-react";
import { errorAction, types as at } from "@authzsvc/api-lib";
import { selectFocusedRequest, isApiCallLoading } from "../../selectors";
import { setBreadcrumbs } from "../../actions/breadcrumbs";

import {
  approveRequest,
  getRequestsToApprove,
  getMyRequests,
} from "../../actions/api";
import { notification } from "antd";

const ConfirmEmail = ({
  //data
  breadcrumbs,
  requestData,
  approvalToken,
  requestLoading,
  approveLoading,
  //actions
  approveRequest,
  getMyRequests,
  getRequestsToApprove,
  setBreadcrumbs,
}) => {
  useEffect(() => {
    setBreadcrumbs(breadcrumbs);
  }, [breadcrumbs, setBreadcrumbs]);

  const handleApprove = async () => {
    const resp = await approveRequest(
      requestData.request.id,
      null,
      approvalToken
    );
    if (resp.type === errorAction(at.APPROVE_REQUEST)) {
      notification["error"]({
        message: resp.payload.title,
        description: resp.payload.data.message,
      });
    }
    await Promise.all([getMyRequests(), getRequestsToApprove()]);
  };

  const renderContent = () => {
    if (requestLoading) {
      return <Loader active inline />;
    }
    if (!requestData.request || requestData.request.status !== "Pending") {
      return (
        <p>
          {requestData.request && requestData.request.status === "Completed"
            ? "The email address has been confirmed"
            : "Invalid email confirmation URL"}
        </p>
      );
    } else {
      return (
        <Form style={{ marginBottom: "10px" }}>
          <p>{requestData.request.displayName}</p>
          <Button
            onClick={() => handleApprove()}
            color="green"
            content="Confirm email"
          />
          {approveLoading && <Loader active inline />}
        </Form>
      );
    }
  };

  return (
    <Container>
      <Segment textAlign="center">{renderContent()}</Segment>
    </Container>
  );
};

export default withRouter(
  connect(
    (state, router) => ({
      history: router.history,
      requestData: selectFocusedRequest(state, router.match.params.id),
      approvalToken: router.match.params.token,
      requestLoading: isApiCallLoading(state, at.GET_REQUESTS_TO_APPROVE),
      approveLoading: isApiCallLoading(state, at.APPROVE_REQUEST),
    }),
    { setBreadcrumbs, approveRequest, getRequestsToApprove, getMyRequests }
  )(ConfirmEmail)
);
